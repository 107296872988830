import { Box, Flex, Img } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {Helmet} from 'react-helmet'   
import { cfg } from "../../dados/dados"; 
import {user,conectarServer,desconectarServer} from "../../conn/WebMaker";
// pages
import Pages from "../../pages/Maker/";
import { Navigate } from "react-router-dom"; 
import MenuMaker from "./Maker/MenuMaker";

export const PageMaker = [ 
    {  nome  :   "Login",       slug   :   "login"          ,page: 'loginMaker',        login: false, url: '/maker/dashboard'},
    {  nome  :   "Dashboard",   links: [{n:'Dashboard',l:'/maker/dashboard'}],   slug   :   "dashboard"      ,page: 'DashboardMaker',    login: true,  url: false},
    {  nome  :   "Operador",    links: [{n:'Dashboard',l:'/maker/dashboard'}],slug   :   "playaovivo"     ,page: 'PlayAoVivo',        login: true,  url: false},
    {  nome  :   "Esportes",    links: [{n:'Dashboard',l:'/maker/dashboard'}],slug   :   "makeraovivo"    ,page: 'MakerAoVivo',       login: true,  url: false},
    // operador
    {  nome  :   "Cadastro de Usuários",  links: [{n:'Usuários',l:'/maker/usuario/lista'},   {n:'Cadastro'}] ,  slug   :   "usuario/cadastro"     ,page: 'CadastroUsuario',       login: true,  url: false},
    {  nome  :   "Lista de Usuários",   links: [{n:'Usuários'}]                                              ,  slug   :   "usuario/lista"        ,page: 'ListaUsuarios',       login: true,  url: false},
    {  nome  :   "Acesso de Usuários",  links: [{n:'Usuários',l:'/maker/usuario/lista'},   {n:'Acesso'}]     ,  slug   :   "usuario/acesso"       ,page: 'CadastroUsuario',       login: true,  url: false},
    // competicoes
    {  nome  :   "Cadastro de Competição",  links: [{n:'Competição',l:'/maker/competicao/lista'},   {n:'Cadastro'}] ,  slug   :   "competicao/cadastro"     ,page: 'CadastroCompeticao',       login: true,  url: false},
    {  nome  :   "Lista de Competição",   links: [{n:'Competição'}]                                                 ,  slug   :   "competicao/lista"        ,page: 'ListaCompeticao',       login: true,  url: false},
    {  nome  :   "Região de Competição",  links: [{n:'Competição',l:'/maker/competicao/lista'},   {n:'Região'}]     ,  slug   :   "competicao/regiao"       ,page: 'ListaCompeticao',       login: true,  url: false},
    
    //partidas 
    {  nome  :   "Cadastro de Partidas",  links: [{n:'Partidos',l:'/maker/partidas/lista'},   {n:'Cadastro'}] ,  slug   :   "partidas/cadastro"    ,page: 'CadastroEsporte',     login: true,  url: false},
    {  nome  :   "Lista de Partidas",     links: [{n:'Partidos'}]                                             ,  slug   :   "partidas/lista"       ,page: 'ListaEsportes',       login: true,  url: false},
        
    // jogadores 
    {  nome  :   "Cadastro de Jogador",  links: [{n:'Jogadores',l:'/maker/jogador/lista'},   {n:'Cadastro'}] ,  slug   :   "jogador/cadastro"    ,page: 'CadastroJogador',     login: true,  url: false},
    {  nome  :   "Lista de Jogador",     links: [{n:'Jogadores'}]                                            ,  slug   :   "jogador/lista"       ,page: 'ListaJogador',       login: true,  url: false},


    // esportes 
    {  nome  :   "Cadastro de Esporte",  links: [{n:'Esportes',l:'/maker/esportes/lista'},   {n:'Cadastro'}] ,  slug   :   "esportes/cadastro"    ,page: 'CadastroEsporte',     login: true,  url: false},
    {  nome  :   "Lista de Esportes",    links: [{n:'Esportes'}]                                             ,  slug   :   "esportes/lista"       ,page: 'ListaEsportes',       login: true,  url: false},
];
const LayoutMaker=(props)=>{ 
    const base =useRef();
    const [web,setWeb] = useState(null); 
    const [reload,setReload] = useState(null); 
    var pag = null;
    PageMaker.forEach((p)=>{ 
        if(props.page[1] == p.slug.toUpperCase()){
            pag =p;
        }else if(props.page[1]+'/'+props.page[2] == p.slug.toUpperCase()){
             pag =p; 
        }
    })  
    if(web == null){
        conectarServer()
    }  
    useEffect(()=>{
        
    },[user]);  
    useEffect(()=>{
        var interV = setInterval(()=>{ 
            if(user!==null && reload!==user.dataUP){ 
                setReload(user.dataUP);
            } 
        },1000) 
        return ()=>{ 
            clearInterval(interV);
            desconectarServer();
        }
    },[])   
    if(pag == null){
        return <Navigate to='/maker/login' />;
    }else if(user==null){
        return 'load';
    }else if(typeof user.usuario =='undefined'){   
        if(pag.login){ 
            pag = PageMaker[0];
        }  
        
        const ViewPage = Pages[pag.page];
        return (
            <Box position='relative'>
                <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
                    <Img opacity={'0.8'}   filter='blur(25px)' objectFit='fill' src={"/assets/"+cfg.site+"/img/maker/bg_operador.webp"}  h={{base:'100%' }} minW={'auto'} w={'100%'}/>  
                </Box>
                <Flex zIndex={'2'} position={'relative'} ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} minH={'100vh'} overflowX={'hidden'} overflowY={'auto'}>
                    <ViewPage/>
                </Flex>
            </Box>
        )  
    }else{
        if(!pag.login){
            pag = PageMaker[0];
            return <Navigate to={pag.url} />;
        }else{  
            const ViewPage = Pages[pag.page];
            return (
                <Flex position={'relative'}  w='100vw' h='100vh' overflowX={'hidden'} overflowY={'auto'}>
                    <Flex position='relative' bg={'tema.bg001'} maxW={'1280px'} m={'0 auto'}> 
                        <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
                            <Img opacity={'0.8'}   filter='blur(25px)' objectFit='fill' src={"/assets/"+cfg.site+"/img/maker/bg_operador.webp"}  h={{base:'100%' }} minW={'auto'} w={'100%'}/>  
                        </Box>
                        <Flex zIndex={'2'}    position={'relative'}  ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} w={'100vw'} h={'auto'}  >
                            <MenuMaker pag={pag} page ={props.page}/>
                            <Flex position={'relative'} pl={{base:'0px',md:'285px'}} pr={'15px'} top={{base: '60px' , md:'90px'}}  direction={'column'}   w='100%' h={'auto'}   >
                                <ViewPage/> 
                            </Flex> 
                        </Flex>
                    </Flex>
                </Flex>
                 
            )
        }
        
    }
    
    
}
export default  LayoutMaker;