import { Button, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { CardBox, CardBoxSub, CardBoxTitulo } from "../../../components/CardBox/CardBox";
import { hexToRGB } from "../../../Function";
import { cfg } from "../../../dados/dados";
import BoxWindow from "../../../components/BoxWindow/BoxWindow";
import { useEffect, useState } from "react";
import ListaResponsiva from "../../../components/ListaResponsiva/ListaResponsiva";
import { GM, sendForm } from "../../../conn/WebMaker";
import { FaEdit, FaKey, FaPen, FaRecycle, FaTrash } from "react-icons/fa";

const ListaUsuarios =function(){
    const [alerta,setAlerta] = useState({});  
    const [erroM,setErroM] = useState({})
    useEffect(()=>{},[alerta]); 
    const [reload,setReload]= useState(0);
    let dados = null; 
    useEffect(()=>{   
        var interV = setInterval(()=>{
            try {
                if(reload!=GM.makerUsarios.up){
                    setReload(GM.makerUsarios.up); 
                }  
            } catch (error) {
                
            }
            
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);
    
    

    const titulos = [
        {nome:'ID',         w:1,    campo:'id'}, 
        {nome:'NOME',       w:9,    campo:'nome'},
        {nome:'ACESSOS',     w:5,    campo:'acesso'},
        {nome:'CRIAÇÃO',    w:3,    campo:'criacao'},
        {nome:'',           w:2,    campo:'cmd'}
    ];
    const campos = [
        {nome:'Código',     tipo    :   'numero',    campo:'id'}, 
        {nome:'Nome',       tipo    :   'texto',     campo:'nome'}, 
        {nome:'Usuário',    tipo    :   'user',      campo:'usuario'}, 
        {nome:'Email',      tipo    :   'email',     campo:'email'}
    ];
    if(typeof GM.makerUsarios !== 'undefined'){
        dados   = []; 
        Object.values(GM.makerUsarios.dados).forEach((d)=>{
            const {id,nome,usuario,email,sAdm,sSit,sOpe,sOdd}=d; 
            dados.push({
                campos  :   d,
                id      :   <Text  noOfLines={'1'}>{id}</Text>, 
                nome    :   
                    <Stack  noOfLines={'2'} w={'100%'} lineHeight={'14px'} gap={'5px'}>
                        <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{nome}</Text>
                        <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>
                            <Text as='span' color={'tema.cor002'} >{usuario}</Text> - <Text as='span' color={'tema.cor001'}>{email}</Text></Text>
                    </Stack>,
                acesso:
                    <Flex direction={'row'}  w={'100%'} lineHeight={'14px'} p='5px 0'> 
                        <Stack  w={'25%'}  gap={'5px'}>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}} color={'tema.cor002'}>SITE</Text>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{(sSit)?'S':'N'}</Text>
                        </Stack>
                        <Stack  w={'25%'}  gap={'5px'}>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}} color={'tema.cor002'}>ADM</Text>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{(sAdm)?'S':'N'}</Text>
                        </Stack>
                        <Stack w={'25%'}  gap={'5px'}>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}} color={'tema.cor002'}>MAR</Text>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{(sOpe)?'S':'N'}</Text>
                        </Stack>
                        <Stack  w={'25%'}  gap={'5px'}>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}} color={'tema.cor002'}>ODD</Text>
                            <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{(sOdd)?'S':'N'}</Text>
                        </Stack>  
                    </Flex>
                    ,
                criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date().toISOString().split('T')[0]}</Text>,
                cmd:<Stack direction={'row'} p='5px' gap={'5px'} align={'center'} justify={'flex-end'}>
                    <Button size='xs' px={'0px'} variant={'outline'} colorScheme="orange">
                        <Icon as={FaPen} ></Icon>
                    </Button>
                    <Button size='xs'px={'0px'} variant={'solid'} colorScheme="red">
                        <Icon as={FaTrash} onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Alerta de exclusão',
                                    size:'md',
                                    mensagem:'Deseja realmente deletar o usuário <font color="red">'+usuario+'</font>?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'green',
                                            cmd:()=>{
                                                sendForm({modulo:'usuario',cmd:'deletar',form:{id:id},callback:(result)=>{
                                                    result.fechar =true;
                                                    result.size = 'md';
                                                    setAlerta(result);
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} ></Icon>
                    </Button>
                </Stack>
            });
        })
    }  
    return (
        <CardBox>
            <CardBoxTitulo textAlign='left'>Lista Usuários</CardBoxTitulo>
            <CardBoxSub>Gerenciamento de Usuário</CardBoxSub> 
            <ListaResponsiva titulos={titulos} dados={dados} campos={campos}/>
            <BoxWindow param={alerta} />
        </CardBox>
    )
}
export default ListaUsuarios;