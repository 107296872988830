import {useEffect, useState} from "react"  
import { Button, Center, Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react"; 
import { cfg } from "../../../dados/dados";
import { hexToRGB } from "../../../Function";
import { sendForm, user } from "../../../conn/WebPainel";
import BoxPainelUser from "../../../layout/modelos/Site/BoxPainelUser";
import { FBPixel } from "../../../Theme";

export default function ModalGamePlay({open=true,game={},onClose}){
    const [alerta,setAlerta] = useState({});   
    const [cmd,setCmd] = useState('');
    const [sendLoad,setSendLoad] = useState(null);
    const [situa,setSitua] = useState('L');
    const [viewGame,setViewGame] = useState(null);
    if(typeof user.usuario == 'undefined'){  
        if(cmd ==''){
            setCmd('register');
            setSitua('R'); 
        }  
    }else {
        if(open && typeof game!=='undefined'&& sendLoad!==game.code ){  
            FBPixel().track('openWindos', {data:'teste'});
            setSendLoad(game.code);
            sendForm({ modulo:'game',cmd:'openGame',form:game,callback:(result)=>{    
                if(typeof result.erro !== 'undefined'){
                //'user_code Sem Saldo!';
                    setCmd('deposit');
                    setSitua('D');  
                }else{
                    setViewGame(result.launch_url);
                    setSitua('G'); 
                } 
            }}); 
        } 
    }
    const view= {};
    if(situa =='D'|| situa =='R'){ 
        return <BoxPainelUser open={open} cmd={cmd} setBox={(props)=>{
            setCmd(props.cmd); 
        }} onClose={()=>{ 
            setCmd('');
            onClose(); 
        }}/>
    }else{
        switch (situa) {
            case 'G':
                view.size = {base:'full',md:'xl' ,lg:'2xl','2xl':'3xl'}; 
                view.titulo = game.nome;  
                view.mensagem=(
                    <Flex position={'relative'} w={'100%'}  flexDirection='row' align='center' justify='center' minH={'calc(100vh - 140px)'}>  
                    {/* //           "overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" */}
                        <iframe  src={viewGame} frameborder="0"  allow="autoplay; fullscreen;"  style={{position:'absolute',zIndex:'10000000'}} height="100%" width="100%"></iframe>
                    </Flex>
                );
            break;
            case 'E':
                view.size = {base:'full',md:'xl' ,lg:'2xl','2xl':'3xl'}; 
                view.titulo = 'Falha no Jogo'; 
                view.mensagem=(
                    <Flex  flexDirection='row' align='center' justify='center'>
                        <Text ml='15px' >Não foi possível abrir o jogo no momento, tente novamente mais tarde!</Text>
                    </Flex>
                );
            break;
            case 'L':
                view.size = 'sm'; 
                view.titulo = 'Carregando Jogo'; 
                view.mensagem=(
                    <Flex  flexDirection='row' align='center' justify='center'>
                        <Spinner color='tema.cor003' /> <Text ml='15px' >Aguarde um momento...</Text>
                    </Flex>
                );
            break; 
        }
        return <Modal isCentered isOpen={open}  size={view.size}  onClose={()=>{
                setSitua('L')
                setSendLoad(null);
                onClose()
            }} >
            <ModalOverlay
                bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)' }
                backdropFilter='blur(10px) hue-rotate(90deg)'
            /> 
            <ModalContent w={'100%'}  bg={'tema.bg001'}  >
                <ModalCloseButton  color={'red.500'} _hover={{bg:'red',color:'tema.bg001',opacity:0.5}}/>
                <ModalHeader color={'tema.cor002'} w='auto' pb={'0px'}>{view.titulo}</ModalHeader>
                <ModalBody  color={'tema.cor001'}>
                    {view.mensagem}
                </ModalBody> 
                <ModalFooter />
                {/*  
                {view.mensagem.length>0?<ModalBody  color={'tema.cor001'}>{view.mensagem}</ModalBody> :<ModalBody /> }
                {view.botao.length>0?<ModalFooter   ><Flex gap={'20px'} w={'100%'}  align='center' justify='center'>{view.botao}</Flex></ModalFooter> :<ModalFooter /> } */} 
            </ModalContent>
        </Modal>
    } 
    
} 