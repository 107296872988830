import { Flex, GridItem, SimpleGrid, Stack } from "@chakra-ui/react"; 
import { cfg } from "../../dados/dados"; 
import PlayAoVivoView from "../../components/Maker/PlayAoVivoView/PlayAoVivoView"; 
const PlayAoVivo=(props)=>{  
    return <> 
        <Flex  direction={{base:'column',lg:'row'}} w={'100%'} minH={'calc(100vh - '+cfg.theme.menu.scroll.top+'px)'} align={'center'}  pt={'140px'}   px='20px' >
            <Stack w={{base:'300px'}}    gap={'20px'}>
                <PlayAoVivoView />
            </Stack>  
        </Flex> 
    </>
}
export default  PlayAoVivo;