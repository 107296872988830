 
import { Box, Flex, GridItem, SimpleGrid, Stack } from "@chakra-ui/react"; 
import { cfg } from "../../../dados/dados";  
import MenuGeral from "../../../layout/modelos/Site/MenuGeral";
import SlideHome from "../../../components/Slider/modelos/SlideHome";
import ListaJogos from "./ListaJogos";
import Jackpot from "./Jackpot";
import { FBPixel } from "../../../Theme";

 const PageHome=(props)=>{  
    const {page=[],onClick=()=>{}} =props; 
    const time = (new Date().getTime()-1712995958431)*0.01; 

    
    FBPixel().pageView()
    
    // For tracking page view
 // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
    // 
    return <Stack  > 
         <Box zIndex={'1'} position='fixed' top={'0px'} left={'0px'} w={'100%'} h={'100%'} bgImage={'/assets/img/site/bg01.webp'} ></Box>
        <Flex w='100%' zIndex={'2'}  direction={{base:'column',md:'row'}} minH={{base:'calc(100vh - 50px)',md:'calc(100vh - 70px)', lg:'calc(100vh - 80px)'}}  className="boxScroll" px='0px'  pt={{base:'50px',md:'70px', lg:'80px'}}  pl={{base:'0px',md:'240px'}}  align={'flex-start'} justify={'center'} >
            <Stack   w={{base:'100%','lg':'3xl' ,'2xl':'6xl'}} maxW={'100%'}  h={'100%'} minH={'calc(100vh - 80px)'}  pt={'10px'} m={'0 auto'}  pl={{base:'10px',md:'0px'}} pr={{base:'10px',xl:'0px'}}  >
                <SlideHome /> 
                <Jackpot min={time*0.9} max={time}/>
                <ListaJogos   titulo='Popular'/> 
            </Stack> 
        </Flex> 
    </Stack>
}
export default  PageHome;