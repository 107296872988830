import { Button, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { CardBox, CardBoxSub, CardBoxTitulo } from "../../../components/CardBox/CardBox";
import { hexToRGB } from "../../../Function";
import { cfg } from "../../../dados/dados";
import BoxWindow from "../../../components/BoxWindow/BoxWindow";
import { useEffect, useState } from "react";
import ListaResponsiva from "../../../components/ListaResponsiva/ListaResponsiva";
import { GM, sendForm } from "../../../conn/WebMaker";
import { FaEdit, FaKey, FaPen, FaRecycle, FaTrash } from "react-icons/fa";

const ListaCompeticao =function(){
    const [alerta,setAlerta] = useState({});  
    const [erroM,setErroM] = useState({})
    useEffect(()=>{},[alerta]); 
    const [reload,setReload]= useState(0);
    let dados = null; 
    useEffect(()=>{   
        var interV = setInterval(()=>{
            try { 
                if(reload!=(GM.makerCompeticao.up+GM.makerPais.up)){ 
                    setReload(GM.makerCompeticao.up+GM.makerPais.up); 
                }  
            } catch (error) {
                
            } 
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);  
    const titulos = [
        {nome:'ID',         w:1,    campo:'id'}, 
        {nome:'NOME',       w:9,    campo:'nome'}, 
        {nome:'REGIÃO',     w:5,    campo:'regiao'}, 
        {nome:'CRIAÇÃO',    w:3,    campo:'criacao'},
        {nome:'',           w:2,    campo:'cmd'}
    ];
    const campos = [
        {nome:'Código',     tipo    :   'numero',    campo:'id'}, 
        {nome:'Nome',       tipo    :   'texto',     campo:'nome'} , 
        {nome:'Região',     tipo    :   'texto',     campo:'regiao'} 
    ]; 
    if(typeof GM.makerCompeticao !== 'undefined'){
        dados   = []; 
        Object.values(GM.makerCompeticao.dados).forEach((d)=>{
            const {id,nome,codpais,criacao}=d;   
            const regiao= GM.makerPais.dados[codpais].nome+' - '+GM.makerPais.dados[codpais].continente;
            d.regiao = regiao;
            dados.push({
                campos  :   d,
                id      :   <Text  noOfLines={'1'}>{id}</Text>, 
                nome    :   
                    <Stack  noOfLines={'2'} w={'100%'} lineHeight={'14px'} gap={'5px'}>
                        <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{nome}</Text> 
                    </Stack>, 
                regiao    :   
                    <Stack  noOfLines={'2'} w={'100%'} lineHeight={'14px'} gap={'5px'}>
                        <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{regiao}</Text> 
                    </Stack>, 
                criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date(criacao).toISOString().split('T')[0]}</Text>,
                cmd:<Stack direction={'row'} p='5px' gap={'5px'} align={'center'} justify={'flex-end'}>
                    <Button size='xs' px={'0px'} variant={'outline'} colorScheme="orange">
                        <Icon as={FaPen} ></Icon>
                    </Button>
                    <Button size='xs'px={'0px'} variant={'solid'} colorScheme="red">
                        <Icon as={FaTrash} onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Alerta de exclusão',
                                    size:'md',
                                    mensagem:'Deseja realmente deletar o esporte <font color="red">'+nome+'</font>?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'green',
                                            cmd:()=>{
                                                sendForm({modulo:'esporte',cmd:'deletar',form:{id:id},callback:(result)=>{
                                                    if(result.erro){
                                                        result.fechar =true;
                                                        result.size = 'md';
                                                        setAlerta(result);
                                                    }else{
                                                        setAlerta({close:true})
                                                    }
                                                    
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} ></Icon>
                    </Button>
                </Stack>
            });
        })
    }  
    return (
        <CardBox>
            <CardBoxTitulo textAlign='left'>Lista Competição</CardBoxTitulo>
            <CardBoxSub>Gerenciamento de Competições</CardBoxSub> 
            <ListaResponsiva titulos={titulos} dados={dados} campos={campos}/>
            <BoxWindow param={alerta} />
        </CardBox>
    )
}
export default ListaCompeticao;