// import PlayAoVivo from './PlayAoVivo'
// import MakerAoVivo from './MakerAoVivo'
import loginGame from './loginGame'
import DashboardGame from './DashboardGame' 

export default {
    // PlayAoVivo,
    DashboardGame, 
    loginGame 
    
}