import { Avatar, Button, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { CardBox, CardBoxSub, CardBoxTitulo } from "../../../components/CardBox/CardBox"; 
import BoxWindow from "../../../components/BoxWindow/BoxWindow";
import { useEffect, useState } from "react";
import ListaResponsiva from "../../../components/ListaResponsiva/ListaResponsiva";
import { GM, sendForm } from "../../../conn/WebMaker";
import {  FaPen,  FaTrash } from "react-icons/fa";

const ListaJogador =function(){
    const [alerta,setAlerta] = useState({});
    useEffect(()=>{},[alerta]); 
    const [reload,setReload]= useState(0);
    let dados = null; 
    useEffect(()=>{   
        var interV = setInterval(()=>{
            try {
                if(reload!=GM.makerJogadores.up){
                    setReload(GM.makerJogadores.up); 
                }  
            } catch (error) {
                
            }
            
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);
    
    

    const titulos = [
        {nome:'ID',         w:1,    campo:'id'}, 
        {nome:'NOME',       w:5,    campo:'nome'},
        {nome:'PAÍS',       w:2,    campo:'pais'},
        {nome:'RANK',       w:2,    campo:'rank'},
        {nome:'ESPORTE',    w:3,    campo:'esporte'},
        {nome:'ORIGEM',     w:2,    campo:'origem'},
        {nome:'CRIAÇÃO',    w:3,    campo:'criacao'},
        {nome:'',           w:2,    campo:'cmd'}
    ];
    const campos = [
        {nome:'Código',     tipo    :   'numero',    campo:'id'}, 
        {nome:'Nome',       tipo    :   'texto',     campo:'nome'}, 
        {nome:'Esporte',    tipo    :   'texto',      campo:'usuario'}
    ];
    if(typeof GM.makerJogadores !== 'undefined'){
        dados   = []; 
        Object.values(GM.makerJogadores.dados).forEach((d)=>{
            const {id,nome,foto,rank,esporte,pais,origem,criacao}=d; 
            dados.push({
                campos  :   d,
                id      :  <Text  noOfLines={'1'}>{id}</Text>, 
                nome    :   
                    <Stack  direction={'row'} w={'100%'} lineHeight={'14px'} gap={'5px'} align={'center'} justify={'flex-start'}>
                        <Avatar
                            size='xs'
                            borderColor='gray.800'
                            name={nome}
                            src={'/p/'+foto}
                            _hover={{ zIndex: "3", cursor: "pointer" }} 
                        />  
                        <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{nome}</Text> 
                    </Stack>,
                
                rank      :  <Text  noOfLines={'1'}>{rank}</Text>, 
                esporte      :  <Text  noOfLines={'1'}>{esporte}</Text>, 
                pais      :  <Text  noOfLines={'1'}>{pais}</Text>, 
                origem      :  <Text  noOfLines={'1'}>{origem}</Text>, 
                criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date(criacao).toISOString().split('T')[0]}</Text>,
                cmd:<Stack direction={'row'} p='5px' gap={'5px'} align={'center'} justify={'flex-end'}>
                    <Button size='xs' px={'0px'} variant={'outline'} colorScheme="orange">
                        <Icon as={FaPen} ></Icon>
                    </Button>
                    <Button size='xs'px={'0px'} variant={'solid'} colorScheme="red">
                        <Icon as={FaTrash} onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Alerta de exclusão',
                                    size:'md',
                                    mensagem:'Deseja realmente deletar o jogador <font color="red">'+nome+'</font>?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'green',
                                            cmd:()=>{
                                                sendForm({modulo:'jogador',cmd:'deletar',form:{id:id},callback:(result)=>{
                                                    result.fechar =true;
                                                    result.size = 'md';
                                                    setAlerta(result);
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} ></Icon>
                    </Button>
                </Stack>
            });
        })
    }  
    return (
        <CardBox>
            <CardBoxTitulo textAlign='left'>Lista Jogadores</CardBoxTitulo>
            <CardBoxSub>Gerenciamento de Jogador</CardBoxSub> 
            <ListaResponsiva titulos={titulos} dados={dados} campos={campos}/>
            <BoxWindow param={alerta} />
        </CardBox>
    )
}
export default ListaJogador;