 
import { Flex, Stack } from "@chakra-ui/react"; 
import { cfg } from "../../dados/dados";  
import PlayAoVivoMaker from "../../components/Maker/PlayAoVivoMaker/PlayAoVivoMaker";
import PlayAoVivoView from "../../components/Maker/PlayAoVivoView/PlayAoVivoView";
const DashboardGame=(props)=>{  
    return <> 
        <Flex direction={{base:'column'}} w={'100%'} minH={'calc(100vh)'} align={'center'}  px={{base:'0px',md:'20px'}} >
             <Stack flex={1} p={'0px'} w={'100%'} h={'100%'}  gap={'20px'}  align={'center'} justify={'flex-start'} >
                <PlayAoVivoMaker  />
            </Stack>  
            <Stack w={{base:'300px'}}    gap={'20px'}>
                <PlayAoVivoView />
            </Stack>     
        </Flex> 
    </>
}
export default  DashboardGame;