import PlayAoVivo from './PlayAoVivo'
import MakerAoVivo from './MakerAoVivo'
import loginMaker from './loginMaker'
import DashboardMaker from './DashboardMaker'
//usuarios
import CadastroUsuario from './Usuarios/CadastroUsuario';
import ListaUsuarios from './Usuarios/ListaUsuarios';

//esportes
import CadastroJogador from './Jogador/CadastroJogador';
import ListaJogador from './Jogador/ListaJogador';
//esportes
import CadastroEsporte from './Esportes/CadastroEsporte';
import ListaEsportes from './Esportes/ListaEsportes';
//competicoes
import CadastroCompeticao from './Competicao/CadastroCompeticao';
import ListaCompeticao from './Competicao/ListaCompeticao';

export default {
    PlayAoVivo,
    MakerAoVivo,
    DashboardMaker,
    loginMaker, 
    
    CadastroUsuario,
    ListaUsuarios,

    CadastroEsporte,
    ListaEsportes,

    CadastroCompeticao,
    ListaCompeticao,

    CadastroJogador,
    ListaJogador
    
}