import {useEffect, useState} from "react"  
import { Button, Center, Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react"; 
import { cfg } from "../../dados/dados";
import { hexToRGB } from "../../Function";
import { Navigate } from "react-router-dom";

const BoxWindow = function(props){ 
    const {size='xs',load = false,titulo='',close = false,url = '',mensagem,fechar=false,botao=[]} = (typeof props.param!='undefined')?props.param:{};
    const [open,setOpen] = useState(false);
    const [tempD,setTempD] = useState(JSON.stringify(props.param));
    useEffect(()=>{ },[tempD]);
    useEffect(()=>{ },[open]); 
    const up = new Date().getTime();
    let view = {
        titulo      :     '',
        mensagem    :   [],
        botao       :   [],
        fechar      :   fechar
    } 
    if(url){
        return <Navigate to={url} />
    }else{
        if(close){
            if(open){
                setOpen(false);
            } 
        }else if(load){
            view.titulo = 'Enviando dados';
            if(!open){
                setOpen(true);
            } 
            view.mensagem.push(
                <Flex key={'msgLoad'+up} flexDirection='row' align='center' justify='center'>
                    <Spinner color='tema.cor003' /> <Text ml='15px' >Aguarde um momento...</Text>
                </Flex>
            );
        }else{ 
            view.titulo = titulo; 
            view.mensagem = [];
            if(typeof mensagem != 'undefined'){
                if(typeof mensagem =='string'){
                    view.mensagem.push(
                        <Text key={'mensgem'+up}  dangerouslySetInnerHTML={{__html: mensagem}}/>
                    ) 
                }else if(Array.isArray(mensagem)){
                    mensagem.forEach((m,i)=>{
                        view.mensagem.push(<Text key={'m'+up+'key'+i} dangerouslySetInnerHTML={{__html: m.mensagem}}></Text>)
                    })
                }else{
                    view.mensagem = mensagem;
                }
            }  
            if(view.fechar && botao.length==0){
                view.botao.push(
                    <Button key={'botao'+up} onClick={()=>{
                        setOpen(false);
                    }} colorScheme="whiteAlpha">OK</Button>
                )
            }else{
                if(botao.length>0){
                    botao.forEach((bt,pos)=>{
                        view.botao.push(
                            <Button key={'botao'+up+'bt'+pos} onClick={()=>{
                                try {
                                    bt.cmd(props.param);
                                } catch (error) {
                                    
                                }
                                if(!bt.close){
                                    setOpen(false);
                                } 
                            }} colorScheme={typeof bt.color=='undefined'?"whiteAlpha":bt.color}>{bt.nome}</Button>
                        )
                    });
                }
                
            }
        }
        return (
            <Modal isCentered isOpen={open}  size={{base:'sm',sm:size}}  onClose={()=>{
                setOpen(false);
            }} >
                <ModalOverlay
                    bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)' }
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                /> 
                <ModalContent w={'100%'}  bg={'tema.bg001'}  >
                    {view.fechar?<ModalCloseButton  color={'red.500'} _hover={{bg:'red',color:'tema.bg001',opacity:0.5}}/>: null}
                    {view.titulo!=''?<ModalHeader color={'tema.cor002'} w='auto' pb={'0px'}>{view.titulo}</ModalHeader>: <ModalHeader/>}
                    {view.mensagem.length>0?<ModalBody  color={'tema.cor001'}>{view.mensagem}</ModalBody> :<ModalBody /> }
                    {view.botao.length>0?<ModalFooter   ><Flex gap={'20px'} w={'100%'}  align='center' justify='center'>{view.botao}</Flex></ModalFooter> :<ModalFooter /> }
                </ModalContent>
            </Modal>
        );
    }
   
}
// class BoxWindow extends React.Component { 
    
//     render(){ 
//         if(this.props.load){
//        
//         }else{
            
//         }
//         var isOpen =true;
//         var onClose = ()=>{

//         }
        
//         return (
//             <> 
               
//             </>
//         )
//     }
// }
export default BoxWindow;