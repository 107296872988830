import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
Number.prototype.formatMoney = function (c, d, t) {
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};
export default function Jackpot({min=0,max=100}){
    const [num,setNum] = useState(min); 
    useEffect(()=>{
        var interV =setInterval(()=>{
            if(num<max){
                setNum((num+0.01));
            }
        },1)
        return ()=>{
            clearInterval(interV);
        }
    },[num])

    return <Flex position={'relative'} w={'100%'}>
        <Image src='/assets/img/site/jackpot.webp' />
        <Flex position={'absolute'} top={'0px'} left={'0px'} w={'full'} h={'full'}  align={'center'} justify={'center'}>
            <Text fontFamily={'Segoe'} fontWeight={'bold'}  bgClip='text'  pt={'5%'}  fontSize={{base:'20px',sm:'30px',md:'40px','lg':'50px' ,'2xl':'60px'}}  bgGradient='linear(to-t, #FEA203, gold)'>
                {num.formatMoney(2, ',', '.')}
            </Text>
        </Flex>
    </Flex>
}