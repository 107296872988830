import Site from "./modelos/Site";
import Maker from "./modelos/Maker"; 
import Game from "./modelos/Game"; 
import 'animate.css'; 
const Layout=(props)=>{ 
    var page = null 
    switch (props.lay.modelo) {  
        case 'game':
            page= <Game content={props.children} page={props.page}/>
        break;
        case 'maker':
            page= <Maker content={props.children} page={props.page}/>
        break;
        default:
            page= <Site content={props.children} page={props.page}/>
        break;
        
    } 
    return page;
}
export default Layout;