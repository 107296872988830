import { useLocation } from "react-router-dom";  
import { cfg } from "./dados/dados";
import Layout from "./layout/Layout"; 
import ReactPixel from 'react-facebook-pixel';
export function FBPixel(){
    return ReactPixel;
};
export const Theme = () => { 
    ReactPixel.init('1332041344039587', null, {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    });

    //meta fim
    const location = useLocation();  
    var pag=[];
    (location.pathname.toUpperCase()+'/').replace('//','/').split('/').forEach((path)=>{
        if(path!=''){
            pag.push(path);
        }
    });  
    var lay = {}
    cfg.layout.forEach((l)=>{ 
        if(l.slug.toUpperCase()==pag[0]){
            lay = l;
        }
    }) 
     
    return (
        <> 
            <Layout page={pag} lay={lay}  ></Layout>
        </>
    )
}