import { Flex, Text } from "@chakra-ui/react"
import { hexToRGB } from "../../Function";
import { cfg } from "../../dados/dados";

export const CardBox =function(props){
    const {children ,window=false, ...rest} =props
    
    var bg = (window)?'transparent':'linear-gradient(178.68deg, rgba('+hexToRGB(cfg.theme.cores.bg002)+', 0.8) 50.88%, rgba(26, 31, 55, 0.4) 100%)'; 
    return (
        <Flex w={'100%'} direction={'column'}  bg={bg} p={'15px'} borderRadius={'lg'} {...rest} >
            {children}
        </Flex>
    )
}
export const CardBoxTitulo =function(props){
    const {children, ...rest } =props; 
    return (
        <Text as={'h2'} w={'100%'} color={'tema.cor001'} textAlign={'left'}  fontSize={'24px'}  p={' 0'}   {...rest} >
            {children}
        </Text>
    )
}
export const CardBoxSub =function(props){
    const {children, ...rest } =props; 
    return (
        <Text opacity={'0.8'} as={'h2'} w={'100%'} color={'tema.cor001'} textAlign={'left'}    p={' 0'}   {...rest} >
            {children}
        </Text>
    )
}