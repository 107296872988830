import { Button, Flex, Icon, Img, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react"; 
import Fn from "../../../Function"; 
import { cfg } from "../../../dados/dados";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdArrowDropdown} from 'react-icons/io'
import { IoMdMenu } from "react-icons/io";
import UserBox from "./UserBox";
import MenuGeral from "./MenuGeral";
const MenuTopo=(props)=>{
    const [fixo,setFixo] = useState(false);
    const [viewMenu,setViewMenu] = useState(false);
    Fn.updatePage =(scroll)=>{ 
        if(scroll>80){
            setFixo(true)
        }else{ 
            setFixo(false)
        }
    } 

    const location = useLocation();  
    const menu= cfg.theme.menu;
        
    return <Flex zIndex={100} position={'absolute'} bg={{base:'tema.bg001',lg:!fixo?'tema.bg001':'tema.bg001'}} transition={'2s'}  direction={'row'} h={{base:'50px',md:'70px', lg:'80px'}} top={{base:'0px',lg: !fixo?menu.t+'px':0}} align={'center'}  justify={'center'} w={'100%'}  >
        <Flex  w={'100%'}  direction={'row'} align={'center'}  justify={menu.align} px={{base:'10px', md:'20px',lg:'0px'}}>
            <Button display={{base:'flex',md:'none'}} size={'lg'} onClick={()=>{
                if(viewMenu){
                    setViewMenu(false);
                }else{
                    setViewMenu(true);
                }
                
            }}  colorScheme="green"  variant={'ghost'} fontSize={'3xl'} p={'10px'} ml={'0px'}>
                <Icon as={IoMdMenu} /> 
            </Button>
            <Stack display={{base:'none',md:'flex'}} w={{base:'140px',md:'230px'}}   align={'center'} justify={'center'}>
                <NavLink to={'/'}>
                    <Img src={"/assets/"+cfg.site+"/logo_menu.webp"}  h={{base:'40px',md:'50px'}}/>
                </NavLink>
            </Stack> 
            <Spacer/>
            <Stack   direction={'row'} h='40px'  align={'center'}    justify={'flex-end'}  spacing={{base:'0px',sm:'10px'}} >
                <UserBox />
            </Stack>
        </Flex> 
        <Stack display={{base:!viewMenu?'none':'flex',md:'flex'}} top={{base:'50px',md:'70px', lg:'80px'}} position={'fixed'} w={{base:'230px'}} h={ {base:'calc(100vh - 50px)',md:'calc(100vh - 70px)', lg:'calc(100vh - 80px)'}}  left={'0px'} overflowY={'auto'} gap={'20px'} >
            <MenuGeral/> 
        </Stack>
    </Flex>
}
export default  MenuTopo;