import { Button, Flex, Icon, Image, InputGroup, Spacer, Stack, Tag, TagLabel, Text } from "@chakra-ui/react";
import { web } from "../../../conn/WebPainel";
 


const BoxGame = function(props){
    const {jogo,flags,onClick} = props;  
    return(
        <Stack w={'100%'}  onClick={onClick}   > 
            <Image     top={'0px'}  borderRadius={'lg'}   w={'100%'}       src={'https://'+web.upload+'/upload/games/'+jogo.id+'.webp'} />
            {/* <Flex w={'100%'}   align={'center'} justify={'flex-start'} gap={'5px'}  fontSize={'14px'} >
                <Icon as={getEsporteIcon(jogo.codesp)} color={'tema.cor002'} fontSize={'16px'}/>
                {new Date(jogo.data).getTime()<srvTime?
                <>
                    <Tag size={'sm'} minW={'50px'}   w={'50px'}alignContent={'center'} justifyContent={'center'} fontSize={'10px'} p={'0 5px'} textAlign={'center'}  fontWeight={'bold'}  variant='outline' colorScheme='whatsapp'> 
                        <TagLabel>AO VIVO</TagLabel>
                    </Tag>
                    <Text color='whatsapp.500'>20'</Text>
                </>:<>
                    <Text color='tema.cor002'>{((new Date(jogo.data).getDate())<10?'0'+(new Date(jogo.data).getDate()):new Date(jogo.data).getDate())}/{((new Date(jogo.data).getMonth()+1)<10?'0'+(new Date(jogo.data).getMonth()+1):new Date(jogo.data).getMonth()+1)} {((new Date(jogo.data).getHours())<10?'0'+(new Date(jogo.data).getHours()):new Date(jogo.data).getHours())}H{((new Date(jogo.data).getMinutes())<10?'0'+(new Date(jogo.data).getMinutes()):new Date(jogo.data).getMinutes())}</Text>
                </>
                }
                
                <Spacer/> 
                {typeof flags[jogo.code]!=='undefined'?<Text as={'span'}  className={"fi fi-"+flags[jogo.code].toLowerCase()} mr={'5px'}  color={'rgba('+hexToRGB(cfg.theme.cores.cor001)+',0.8)'}   w='20px' h='16px' borderRadius={'4px'} ></Text>:null}
                <Text  textTransform='uppercase'color={'rgba('+hexToRGB(cfg.theme.cores.cor001)+',0.5)'} noOfLines={'1'} title={jogo.competicao}>{jogo.competicao}</Text>
            </Flex>
            <Stack direction={{base:'column',lg:'row' ,xl:'column' ,'2xl':'row'}}>
                <Flex w={'100%'} color={'tema.cor001'}  align={'center'} justify={'flex-start'} gap={'5px'}  fontSize={'14px'} >
                    <Stack p={0} gap={'0px'}>
                        <Flex gap={'5px'} align={'flex-start'} justify={'flex-start'}>
                            <Text maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.casa1}</Text>
                            <Text w={'20px'} textTransform={'uppercase'} noOfLines={'1'}>E</Text>
                            <Text  maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.casa2}</Text>
                        </Flex>
                        <Flex gap={'5px'} align={'flex-start'} justify={'flex-start'}>
                            <Text maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.fora1}</Text>
                            <Text w={'20px'} textTransform={'uppercase'} noOfLines={'1'}>E</Text>
                            <Text maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.fora2}</Text>
                        </Flex> 
                    </Stack>
                    <Spacer/>
                    <PlacarAovivo jogo={jogo.id} />
                </Flex>
                <Flex     color={'tema.cor001'}    align={'center'} justify={'flex-end'} gap={'20px'}  fontSize={'14px'} >
                    <Flex>
                        <BoxBetODDs/>
                     </Flex>
                    <Flex>
                        <BoxBetODDs/>
                    </Flex>
                    <Button w={'30px'} fontSize={'14px'} flexDirection={'column'} colorScheme="orange" variant={'outline'}>
                         <Icon fontSize={'18px'} as={IoIosArrowForward}/>
                    </Button>
                </Flex>
            </Stack>
             */}
        </Stack>
    )
}  
export default BoxGame;