import { createIcon } from "@chakra-ui/react";
export const Fa777 = createIcon({
  displayName: "777",
  viewBox: "0 0 5100 5120",
  path: (
    <>
      <path
        fill="currentColor"
        d="M452 5100c-108-29-187-75-273-160-87-87-140-182-164-295-22-104-22-1946 0-2049 47-224 237-414 461-461 103-22 4064-22 4167 0 226 48 414 235 462 461 22 103 22 1945 0 2049-23 109-78 208-165 295-86 87-162 131-276 160-76 20-117 20-2108 19-1967 0-2033-1-2104-19zm4179-415c23-16 52-48 63-70 21-40 21-49 24-986l2-946-28-54c-22-43-38-59-81-81l-53-28H562l-53 28c-43 22-59 38-81 81l-28 53v930c0 706 3 941 12 972 15 49 75 112 120 125 21 6 794 9 2045 8l2011-2 43-30z"
      />
      <path
        fill="currentColor"
        d="M951 4320c-95-33-175-61-177-63s66-212 151-468c85-255 155-465 155-466 0-2-72-3-160-3H760v-400h878l-242 728c-162 484-247 728-257 729-8 1-92-25-188-57zM2351 4320c-95-33-175-61-177-63s66-212 151-468c85-255 155-465 155-466 0-2-72-3-160-3h-160v-400h878l-242 728c-162 484-247 728-257 729-8 1-92-25-188-57zM3751 4320c-95-33-175-61-177-63s66-212 151-468c85-255 155-465 155-466 0-2-72-3-160-3h-160v-400h435c342 0 435 3 435 13 0 6-107 334-238 727-159 476-243 716-253 717-8 1-92-25-188-57zM0 1520v-200h960v400H0v-200zM1611 1698C1563 1569 1010 12 1010 7c0-4 250 118 555 271l555 277 220-220 220-220 222 222 221 221 550-274c302-151 551-274 553-272s-133 386-300 854l-304 849-941 3-941 2-9-22zm1623-420c62-167 165-462 162-465-2-2-110 49-240 114l-236 118-180-180-180-180-180 180-180 180-236-118c-130-65-238-116-240-114-3 3 100 298 162 465l16 42h1316l16-42zM3960 1520v-200h1160v400H3960v-200z"
      />
    </>
  )
})
export const FaBeachTennis = createIcon({
    displayName: "BeachTennis",
    viewBox: "0 0 200 200",
    path: (
      <>
        <path
          fill="currentColor"
          d="M139.44 130.32c3-10.75 12-20.25 12-20.25 16.5-16 18-37 18-37 1.25-58-72.75-58-71.5 0 0 0 1.5 21 18 37 0 0 9 9.5 12 20.25l1.92 6.44h7.25z"
        />
        <path
          fill="currentColor"
          d="M114.05 59.6a2 2 0 11-2-2 2 2 0 012 2zM114.05 66.64a2 2 0 11-2-2 2 2 0 012 2zM114.05 74.26a2 2 0 11-2-2 2 2 0 012 2zM114.05 81.31a2 2 0 11-2-2 2 2 0 012 2zM114.05 88.26a2 2 0 11-2-2 2 2 0 012 2zM155.84 59.6a2 2 0 11-2-2 2 2 0 012 2zM155.84 66.64a2 2 0 11-2-2 2 2 0 012 2zM155.84 74.26a2 2 0 11-2-2 2 2 0 012 2zM155.84 81.31a2 2 0 11-2-2 2 2 0 012 2zM155.84 88.26a2 2 0 11-2-2 2 2 0 012 2zM121 52.31a2 2 0 11-2-2 2 2 0 012 2zM121 59.35a2 2 0 11-2-2 2 2 0 012 2zM121 67a2 2 0 11-2-2 2 2 0 012 2zM121 74a2 2 0 11-2-2 2 2 0 012 2zM121 81a2 2 0 11-2-2 2 2 0 012 2zM121 88.26a2 2 0 11-2-2 2 2 0 012 2zM121 95.31a2 2 0 11-2-2 2 2 0 012 2zM149.09 52.31a2 2 0 11-2-2 2 2 0 012 2zM149.09 59.35a2 2 0 11-2-2 2 2 0 012 2zM149.09 67a2 2 0 11-2-2 2 2 0 012 2zM149.09 74a2 2 0 11-2-2 2 2 0 012 2zM149.09 81a2 2 0 11-2-2 2 2 0 012 2zM149.09 88.26a2 2 0 11-2-2 2 2 0 012 2zM149.09 95.31a2 2 0 11-2-2 2 2 0 012 2z"
        />
        <path
          fill="currentColor"
          d="M133.34 114.78h-5.23s-1.21-.57-2.34-3.61c0 0-1.2-2 5.2-4.07l2.19-.63 2.05.63c6.4 2.06 5.19 4.07 5.19 4.07-1.13 3-2.33 3.61-2.33 3.61h-5.23"
        />
        <path
          fill="currentColor"
          d="M128.57 135.51L128.57 143.51 128.57 166.01 124.94 166.01 124.94 173.64 132.19 173.64 133.98 173.64 141.23 173.64 141.23 166.01 137.61 166.01 137.61 143.51 137.61 135.28"
        />
        <path
          fill="currentColor"
          d="M134.18 127.89c2.32-2.71 3-8.84 3-8.84h-7s.67 6.13 3 8.84l.51.75z"
        />
        <path
          fill="currentColor"
          d="M128.53 146.23l9.33-3.39m-9.3 10.16l9.33-3.39m-9.28 10.14l9.32-3.39"
        />
        <path
          fill="currentColor"
          d="M60.56 76.65c-3 10.75-12 20.25-12 20.25-16.5 16-18 37-18 37-1.25 58 72.75 58 71.5 0 0 0-1.5-21-18-37 0 0-9-9.5-12-20.25l-1.92-6.44h-7.25z"
        />
        <path
          fill="currentColor"
          d="M86 147.37a2 2 0 112 2 2 2 0 01-2-2zM86 140.33a2 2 0 112 2 2 2 0 01-2-2zM86 132.71a2 2 0 112 2 2 2 0 01-2-2zM86 125.66a2 2 0 112 2 2 2 0 01-2-2zM86 118.71a2 2 0 112 2 2 2 0 01-2-2zM44.16 147.37a2 2 0 112 2 2 2 0 01-2-2zM44.16 140.33a2 2 0 112 2 2 2 0 01-2-2zM44.16 132.71a2 2 0 112 2 2 2 0 01-2-2zM44.16 125.66a2 2 0 112 2 2 2 0 01-2-2zM44.16 118.71a2 2 0 112 2 2 2 0 01-2-2zM79 154.66a2 2 0 112 2 2 2 0 01-2-2zM79 147.62a2 2 0 112 2 2 2 0 01-2-2zM79 140a2 2 0 112 2 2 2 0 01-2-2zM79 133a2 2 0 112 2 2 2 0 01-2-2zM79 126a2 2 0 112 2 2 2 0 01-2-2zM79 118.71a2 2 0 112 2 2 2 0 01-2-2zM79 111.66a2 2 0 112 2 2 2 0 01-2-2zM50.91 154.66a2 2 0 112 2 2 2 0 01-2-2zM50.91 147.62a2 2 0 112 2 2 2 0 01-2-2zM50.91 140a2 2 0 112 2 2 2 0 01-2-2zM50.91 133a2 2 0 112 2 2 2 0 01-2-2zM50.91 126a2 2 0 112 2 2 2 0 01-2-2zM50.91 118.71a2 2 0 112 2 2 2 0 01-2-2zM50.91 111.66a2 2 0 112 2 2 2 0 01-2-2z"
        />
        <path
          fill="currentColor"
          d="M66.66 92.19h5.23s1.21.57 2.34 3.61c0 0 1.2 2-5.2 4.07l-2.19.63-2-.63c-6.4-2.05-5.19-4.07-5.19-4.07 1.13-3 2.33-3.61 2.33-3.61h5.23"
        />
        <path
          fill="currentColor"
          d="M71.43 71.46L71.43 63.46 71.43 40.96 75.06 40.96 75.06 33.33 67.81 33.33 66.02 33.33 58.77 33.33 58.77 40.96 62.39 40.96 62.39 63.46 62.39 71.69"
        />
        <path
          fill="currentColor"
          d="M65.82 79.08c-2.32 2.71-3 8.84-3 8.84h7s-.67-6.13-3-8.84l-.51-.75z"
        />
        <path
          fill="currentColor"
          d="M71.47 60.74l-9.33 3.39M71.44 54l-9.33 3.4m9.28-10.15l-9.32 3.39"
        />
      </>
    ),
  });