import { AbsoluteCenter, Box, Divider, Flex, FormControl, FormLabel, Stack, Switch, Text } from "@chakra-ui/react";
import { CardBox, CardBoxSub, CardBoxTitulo } from "../../../components/CardBox/CardBox";
import { hexToRGB } from "../../../Function";
import { cfg } from "../../../dados/dados";
import BoxWindow from "../../../components/BoxWindow/BoxWindow";
import { useEffect, useState } from "react";
import { GM, sendForm } from "../../../conn/WebMaker";
import InputCampo from "../../../components/Input/modelos/InputCampo";
import { FaCheck, FaGlobe, FaIndent, FaKey, FaMailBulk, FaPlus, FaSignInAlt, FaUser, FaUserPlus, FaUsers } from "react-icons/fa";
import { Bt1 } from "../../../components/Button/ButtonView";
import SelectCampo from "../../../components/Input/modelos/SelectCampo";
import { HiUserGroup } from "react-icons/hi";

const CadastroCompeticao =function(){
    const [alerta,setAlerta] = useState({});  
    const [erroM,setErroM] = useState({})
    console.log(GM)

    let dados = null; 
    
    const [reload,setReload]= useState(0);
    useEffect(()=>{   
        var interV = setInterval(()=>{
            try {
                if(reload!=GM.makerGrupos.up){
                    setReload(GM.makerGrupos.up); 
                }  
            } catch (error) {
                
            } 
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);

    useEffect(()=>{

    },[alerta]) 
    var viewOP = [];
    console.log(GM.makerPais);
    if(typeof GM.makerPais!='undefined'){
        Object.values(GM.makerPais.dados).sort((a, b)=>{  
            return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0; 
        }).forEach((g)=>{
            viewOP.push({nome:g.nome+' - '+g.continente,valor:g.id})
        })
    }   
    return (
        <CardBox px='25px'>
            <CardBoxTitulo textAlign='left'>Cadastro Competição</CardBoxTitulo>
            <CardBoxSub>Preencha todos os dados abaixo:</CardBoxSub>
            <Box pos={'relative'}   as={'form'} py={'15px'}  onSubmit={(e)=>{
                e.preventDefault();   
                setAlerta({load:true}) 
                let json = {};  
                new FormData(e.target).forEach(function(value, prop){ 
                    json[prop] = value;
                }); 
                sendForm({ modulo:'competicao',cmd:'cadastro',form:json,callback:(result)=>{   
                    if(result.erro){
                        result.fechar =true;
                        result.size = 'md';  
                        setAlerta(result);
                    }else{  
                        setAlerta({url:'maker/competicao/lista/'});  
                    } 
                }}); 
            }} >
                <Stack w={'100%'} direction={'row'} gap={'20px'}>
                    <InputCampo
                        icone={FaIndent} 
                        titulo='Nome'
                        name='nome'
                        placeholder="Digite o nome completo"
                        error={erroM.login}
                        w='60%'
                    />
                    <SelectCampo
                        icone={FaGlobe} 
                        titulo='Região'
                        name='codpais'
                        placeholder="Selecione a região"
                        error={erroM.login}
                        value={viewOP}
                        w='40%'
                    />
                </Stack> 
                <Bt1 bg1={ '#000000'} bg2={ '#222222'}   leftIcon={<FaPlus />}   mt={'20px'} >Cadastrar Competição</Bt1>
            </Box>
            <BoxWindow param={alerta} />
        </CardBox>
    )
}
export default CadastroCompeticao;