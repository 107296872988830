import { Avatar, AvatarGroup, Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { hexToRGB,TempoCronometro } from "../../../Function";
import { cfg } from "../../../dados/dados";
import GradientBorder from "../../VisionUI/GradientBorder";
import { GM, sendForm, srvTime } from "../../../conn/WebGame";
import { useEffect, useState } from "react"; 
import { Bt1 } from "../../Button/ButtonView";
import BoxWindow from "../../BoxWindow/BoxWindow";
 
const ListaAoVivoMaker =function() {  
    const [alerta,setAlerta] = useState({});

    if(typeof GM.jogosAoVivo == 'undefined'){
        GM.jogosAoVivo={up:0,dados:{}}; 
    }
    const [reload,setReload]= useState(GM.jogosAoVivo.up)
    useEffect(()=>{   
        var interV = setInterval(()=>{
            if(reload!=GM.jogosAoVivo.up){
                setReload(GM.jogosAoVivo.up); 
            } 
        },1000) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload,alerta]);
    useEffect(()=>{  },[reload]); 
    let listaView = null
    let dados = (typeof GM.jogosAoVivo== 'undefined')?null:Object.values(GM.jogosAoVivo.dados).sort((a, b)=>{  
        return a.data < b.data ? -1 : a.data > b.data ? 1 : 0; 
    }); 
    if(dados == null){
        listaView = <Text>Load dados</Text>
    }else{
        if(Object.keys(dados).length>0){
            listaView = [];
            dados.forEach((v)=>{ 
                listaView.push(
                    <GradientBorder w='calc(100% - 20px)' key={"listAoVivo"+v.id} borderRadius='20px'> 
                        <Stack position={'relative'} w='100%' direction={'row'} p={'0px'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.7)'} borderRadius={'20px'} gap={'3px'}>
                            <Box position={'absolute'} left={'50%'} top={'3px'} transform={'translateX(-50%)'}>
                                <GradientBorder   borderRadius='15px' p={'1px'}> 
                                    {new Date(v.data).getTime()<=srvTime?
                                        <Box color={'tema.cor001'} w={'100px'} lineHeight='10px' fontSize={'8px'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg002)+',0.7)'}  borderRadius='15px'>
                                            <Text>Começou a:</Text>
                                            <Text py={'4px'} color={'red.400'} fontWeight={'bold'} fontSize={'12px'}><TempoCronometro view={'tempo'} time={new Date(v.data).getTime()} /></Text>
                                        </Box>:
                                        <Box color={'tema.cor001'} w={'100px'} lineHeight='10px' fontSize={'8px'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.7)'}  borderRadius='15px'>
                                            <Text>Começa em:</Text>
                                            <Text py={'4px'} color={'tema.cor002'} fontWeight={'bold'} fontSize={'12px'}><TempoCronometro view={'tempo'} time={new Date(v.data).getTime()} /></Text>
                                        </Box>
                                    }
                                    
                                </GradientBorder> 
                                <Button mt={'2px'}  h={'24px'} colorScheme="blackAlpha" bg={'tema.bg001'} onClick={()=>{
                                    setAlerta({load:true})
                                    setTimeout(()=>{
                                        setAlerta({
                                            fechar:true,
                                            titulo:'Atenção',
                                            size:'md',
                                            mensagem:'Deseja realmente iniciar a partida <br/><font color="'+cfg.theme.cores.cor003+'"><b>'+v.jg01+'</b></font> '+(v.jg02!=null?' e <font color="'+cfg.theme.cores.cor003+'"><b>'+v.jg02+'</b></font>':null)+' <br/>vs<br/> <font color="'+cfg.theme.cores.cor002+'"><b>'+v.jg03+'</b></font> '+(v.jg04!=null?' e <font color="'+cfg.theme.cores.cor002+'"><b>'+v.jg04+'</b></font>':null)+'?', 
                                            botao:[
                                                {
                                                    nome:'SIM',
                                                    color:'green',
                                                    cmd:()=>{
                                                        sendForm({modulo:'operador',cmd:'iniciarPartida',form:{id:v.id},callback:(result)=>{
                                                            result.fechar =true;
                                                            result.size = 'md';
                                                            setAlerta(result);
                                                        }});
                                                        setAlerta({load:true})
                                                    }
                                                },
                                                {
                                                    nome:'NÃO',
                                                    color:'red'
                                                }
                                            ]
                                        });
                                    })
                                    
                                    
                                }} >INICIAR</Button>
                            </Box>    
                            <Flex w={'50%'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)'}   pb={'5px'} direction={'row'} align={'center'} justify={'flex-start'} borderRadius={'20px  0 0 20px'}>
                                <AvatarGroup size='md' max={2} mt='-20px' ml='-10px'>
                                    <Avatar  name={v.jg01} src={'/p/'+v.img01} />
                                    {v.jg02!=null?  <Avatar   name={v.jg02} src={'/p/'+v.img02}/> :null} 
                                </AvatarGroup>
                                <Stack gap={'0px'} p={'10px 10px 10px 10px'} h={'100%'} w={'100%'}  fontSize={{base:'10px',sm:'14px'}}   color={'tema.cor001'} align={'center'} justify={'center'} textAlign={'left'}>
                                    <Text h={'14px'} w={'100%'}  noOfLines={1}>{v.jg01}</Text>
                                    {v.jg02!=null?<Text h={'14px'}    w={'100%'} noOfLines={1}>{v.jg02}</Text> :null}  
                                </Stack>
                            </Flex>
                            <Flex w={'50%'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.5)'}   pb={'5px'} direction={'row'} align={'center'} justify={'flex-end'} borderRadius={' 0 20px 20px 0'}> 
                                <Stack gap={'0px'} p={'10px 10px 10px 10px'} h={'100%'} w={'100%'}  fontSize={'14px'}   color={'tema.cor001'} align={'center'} justify={'center'} textAlign={'right'}>
                                    <Text h={'14px'} w={'100%'}  noOfLines={1} >{v.jg03}</Text>
                                    {v.jg04!=null?<Text h={'14px'}    w={'100%'} noOfLines={1}>{v.jg04}</Text> :null}  
                                </Stack>
                                <AvatarGroup size='md' max={2} mt='-20px' mr='-10px'>
                                    <Avatar  name={v.jg03} src={'/p/'+v.img03} />
                                    {v.jg04!=null?  <Avatar   name={v.jg04} src={'/p/'+v.img04}/> :null} 
                                </AvatarGroup>
                            </Flex> 
                        </Stack>   
                    </GradientBorder>
                )
            })
            

        }else{
            listaView = <Text color={'tema.cor001'}>Nenhum Jogo pendente</Text>
        } 
    }
        
    return (
        <Flex h={'100%'} pt={'20px'} pb={'10px'} direction={'column'} minW={'100%'} gap={'20px'}   align={'center'} justify={'flex-start'} overflow={'hidden'} overflowY={'auto'} px={'10px'}>
            {listaView}
            <BoxWindow param ={alerta} />
        </Flex>
    )
}
export default ListaAoVivoMaker;