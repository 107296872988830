import { Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Icon, IconButton, Stack, Text, useDisclosure } from "@chakra-ui/react";
import Input from "../Input";
import { useEffect, useRef, useState } from "react";
import InputCampo from "../Input/modelos/InputCampo";
import { FaFilter, FaIndent, FaPlus, FaTimes } from "react-icons/fa";
import SelectCampo from "../Input/modelos/SelectCampo";
import { LuFilterX } from "react-icons/lu";
import { IoAddCircle } from "react-icons/io5";


 
const BoxFiltro = function(props){
    const {titulo = 'Filtro Avançado',btT ='Filtro',campos = [],cash='temp',filtro,setFiltro} = props; 
    const [alerta,setAlerta] = useState(''); 
    const [campo,setCampo] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef();  
    var viewOps = [];
    campos.forEach((el,id) => {
        viewOps.push({
            nome    :   el.nome,
            valor   :   id
        })
    }); 
    let viewIP = null; 
    let c = {}
    if(campo!=='' && typeof campos[campo] != 'undefined'){
        c = campos[campo]; 
        viewIP = <Box as='form' onSubmit={(e)=>{
            e.preventDefault();  
            let json = {};  
            new FormData(e.target).forEach(function(value, prop){ 
                json[prop] = value;
            });
            if(json.campo ==''){
                setAlerta(c.nome+' não pode ser vazio');
            }else{
                let tempF =filtro;
                tempF.push({
                    nome    :   c.nome,
                    tipo    :   'LIKE',
                    campo   :   c.campo,
                    valor   :   json.campo
                }) 
                setFiltro(tempF);
            }  
            e.target.reset()
            // setAlerta({load:true}) 
            //  
             
            // sendForm({ modulo:'usuario',cmd:'cadastro',form:json,callback:(result)=>{   
            //     if(result.erro){
            //         result.fechar =true;
            //         result.size = 'md';  
            //         setAlerta(result);
            //     }else{  
            //         setAlerta({close:true});  
            //     } 
            // }}); 
        }}>
            <InputCampo  
                name='campo'
                placeholder={c.nome}
                error={alerta}
                w='60%'
                size='md'
                onFocus={()=>{
                    setAlerta('');
                }} 
                bt={<Button type="submit"    size='md' fontSize={'12px'} px={'20px'} borderRadius={'0 20px 20px 0'}  colorScheme="orange" >FILTRAR</Button>}
            />
        </Box>
    } 
    return (
      <Flex gap={'10px'} direction={{base:'column',md:'row'}}>
        <Button ref={btnRef} size={{base:'sm',md:'md',lg:'lg'}} colorScheme='blackAlpha' onClick={onOpen} >
          {btT}
        </Button>
        {filtro.length>0?<Button  size={{base:'sm',md:'md',lg:'lg'}} colorScheme='red' onClick={()=>{
            setFiltro([]);
        }} >LIMPAR FILTRO</Button>:null}
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent bg={'tema.bg001'}>
            <DrawerCloseButton color="red" />
            <DrawerHeader color={'tema.cor001'}>{titulo}</DrawerHeader> 
            <DrawerBody> 
                <SelectCampo
                    icone={FaIndent}  
                    name='grupo'
                    placeholder="Selecione o Campo"
                    value={viewOps} 
                    onChange={(el)=>{
                        setCampo(el.target.value) 
                    }}
                    w='40%'
                />
                {viewIP}

                {
                    (filtro.length==0)?
                        <Text w='100%' py={'10px'} textAlign={'center'} color={'tema.cor001'}>Nenhum filtro aplicado</Text>
                    :
                    <>
                        <Text w='100%' py={'10px'} textAlign={'left'} color={'tema.cor001'}>Filtro Aplicado:</Text>
                        <Divider w={'100%'}/>
                        <Stack w={'full'} gap={'10px'} py={'10px'} overflowY={'auto'}>
                            {filtro.map((f,pos)=>{
                                return (
                                    <Flex key={'pos'+pos} bg={'tema.bg002'} align={'center'} justify={'center'} pr={'10px'}  borderRadius={'10px'}>
                                        <Stack lineHeight={'10px'} gap={'4px'} w={'full'} p={'10px'} align={'flex-start'} justify={'center'} textAlign={'left'}>
                                            <Text fontSize={'10px'} color={'tema.cor002'}>{f.nome}</Text>
                                            <Text fontSize={'14px'} lineHeight={'14px'} color={'tema.cor001'}>{f.valor}</Text>
                                        </Stack> 
                                        <Button onClick={()=>{
                                            var temp = []
                                            filtro.forEach((v,i)=>{
                                                if(i!=pos){
                                                    temp.push(v);
                                                }
                                            })
                                            setFiltro(temp); 
                                        }} bg={'red'} p={'3px'} size={'xs'}  variant={'ghost'}><Icon as={FaTimes}/></Button>
                                    </Flex>
                                )
                            })} 
                        </Stack> 
                    </>
                }    
            </DrawerBody> 
            <DrawerFooter />
          </DrawerContent>
        </Drawer>
      </Flex>
    )
}
export default BoxFiltro;