import { AbsoluteCenter, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Divider, Flex, Icon, IconButton, Image, Spacer, Stack, Text, Wrap } from "@chakra-ui/react";
import { FaBell, FaList, FaPlus, FaTimes, FaUber, FaUser, FaUserCheck, FaUserCircle, FaUserPlus, FaUserSlash, FaUsers, FaUsersCog } from "react-icons/fa"; 
import { cfg } from "../../../dados/dados"; 
import { hexToRGB } from "../../../Function";
import { user } from "../../../conn/WebGame";
import { useRef, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { IoMenu } from "react-icons/io5";
import { MdLogout, MdOutlineSportsTennis } from "react-icons/md";
import { LuClipboardCheck } from "react-icons/lu";
import { CiLogout } from "react-icons/ci";


var cor ={
    bg :'linear-gradient(178.68deg, rgba('+hexToRGB(cfg.theme.cores.bg002)+', 0.94) 31.88%, rgba(26, 31, 55, 0) 100%)',
    mS : '#1A1F37'
}
const MenuGame= function(props){ 
    const {pag = {nome:'', links:[]}} = props; 
    const [menuV, setMenuV] = useState(false)
    const page= {
        modulo  : props.page[0]+'/'+props.page[1],
        url     : props.page[0]+'/'+props.page[1]+'/'+props.page[2]
    };  
    const PAGs = [
        {icon:LuClipboardCheck, modulo:'/game/dashboard',nome:'Marcação'}
    ]
    return (
        <>
            <Box   position={'fixed'} className="animate__animated animate__fadeIn"  display={{base:menuV?'flex':'none',md:'none'}} w={'100vw'} h={'100vh'}  zIndex={'11'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)' }  backdropFilter='blur(10px) hue-rotate(90deg)' ></Box>
            <Flex position={'fixed'}  className={{base:"animate__animated animate__fadeInLeft",md:''}} zIndex={'12'}   display={{base:menuV?'flex':'none',md:'flex'}} direction={'column'}  left={{base:'0px',md:'10px',"xl": '50%'}} ml={{'xl':'-630px'}}   w={{base:'200px',md:'260px'}} px={{base:'10px',md:'20px'}} align={'flex-start'} justify={'flex-start'} height={'100vh'} backdropBlur={'30px'} bg={ cor.bg} overflowX={'hidden'} overflowY={'auto'} >
                <Button onClick={()=>{
                    setMenuV(false)
                }} display={{base:menuV?'flex':'none',md:'none'}} size={'xs'} left={'0px'} leftIcon={<FaTimes />} position={'absolute'} colorScheme="red" zIndex={2} variant={'ghost'} borderRadius={'0px'} >FECHAR</Button>
                <Stack position={'relative'} align={'center'} justify={'flex-start'} w={'100%'} py={'20px'}   direction={'column'} >
                    <Image mt={{base:'20px',md:'0px'}} h={{ base:'40px',md:'50px' }}  src={"/assets/"+cfg.site+"/logo_menuB.webp"} alt={'Logo '+cfg.nome} /> 
                    <Text   fontSize={{base:'12px',md:'16px'}} color={'tema.cor001'}  >Marcador</Text>
                    <Divider  h='3px' my={{base:'0px',md:'0px'}} borderBottom={'3px'} borderTop={'2px '} borderColor={{base:'tema.cor001',md:'tema.cor001'}} borderRadius={'100%'} />
                    <BoxMenuMaker  PAGs={PAGs} page={page} cmd={()=>{setMenuV(false)}}/> 
                </Stack>   
            </Flex>
            <Flex  px={{base:'0px',md:'10px'}} zIndex={'10'} position={{base:'fixed',md:'absolute'}} w={ {base : '100%' , md:'calc(100vw - 270px)',xl:'1010px'}} left={{base:'0px',md:'270px'}} h={{base:'50px',md:'90px'}} align={'center'} justify={'center'}  bg={{base:'tema.bg001',md:'transparent'}} >
                <Button onClick={()=>{
                    if(menuV){
                        setMenuV(false)
                    }else{
                        setMenuV(true)
                    }
                }} display={{base:'flex',md:'none'}} w={'50px'} h={'50px'} mr={'5px'} variant={'ghost'} colorScheme="orange" borderRadius={'0px'}>
                    <Icon as={IoMenu} h={'30px'} w='30px'   />
                </Button>
                <Stack color={'tema.cor002'} textAlign={'left'} gap={'0px'} > 
                    <Stack>
                        <Text as={'h1'} fontWeight={'bold'}  px={'5px'} fontSize={{base:'12px',sm:'14px',md:'24px'}}>{pag.nome}</Text>
                    </Stack> 
                </Stack>
                <Spacer/>
                <Button aria-label='Search database' px={'15px'} size={{base:'sm',md:'lg'}} leftIcon={<FaUserCircle />} color={'tema.cor001'} colorScheme="whiteAlpha" variant={'ghost'}>
                    <Text  maxW={{base:'60px',sm:'80px',md:'120px'}} noOfLines={1}>
                        {user.usuario} 
                    </Text>
                </Button> 
                <IconButton aria-label='Search database' size={'lg'} color='red' icon={<MdLogout />}  colorScheme="whiteAlpha" variant={'ghost'} />
            </Flex>
        </>
        
         
    )
}
const BoxMenuMaker = function(props){
    const {PAGs=[],page={},pag,cmd}=props; 
    let view = [];
    var posN = 0;
    var index = 0;
    PAGs.forEach((pg,pos)=>{
        const {icon=null,nome,modulo,sub= []}=pg
        if(sub.length>0){
            if(page.modulo.replace(/\//g,'') == modulo.toUpperCase().replace(/\//g,'')){ 
                index = pos;
            } 
            view.push( 
                <AccordionItem   key={'menu'+posN}  border={'0'} color={'tema.cor001'}  p={'0px'}   borderRadius='10px'  >
                    <h2 > 
                        <AccordionButton  p={{base:'5px',md:'10px 10px'}}  > 
                            {icon==null?null:<Icon as={icon} w={{base:'24px',md:'34px'}} h={{base:'24px',md:'34px'}} borderRadius={'lg'}  p={'6px'}  bg={'tema.cor003'}/>}
                            <Box as="span" flex='1' lineHeight={{base:'24px',md:'34px'}} textAlign='left' textIndent={'10px'}>
                                {nome}
                            </Box>
                            {sub.length>0?<AccordionIcon   />:null} 
                        </AccordionButton> 
                    </h2>
                    <AccordionPanel p={'6px'} pb={'8px'}> 
                        <Stack borderRadius={'lg'} overflow={'hidden'} gap={'2px'}>
                            {pg.sub.map((s,id)=>{
                                posN++;
                                var select= false;
                                if(page.url.replace(/\//g,'') == s.url.toUpperCase().replace(/\//g,'')){
                                    select = true;
                                } 
                                return (
                                    <NavLink key={'menusub'+(posN-1)}  to={s.url} onClick={()=>{
                                        cmd()
                                    }}>
                                        <Button  bg={(select?'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.2)':'transparent' )} minH={'auto'}  w='100%'  p={{base:'10px'}} gap={{base:'5px',md:'10px'}}  _hover={{bg:'tema.bgh003'}}>
                                            {s.icon==null?null:<Icon  as={s.icon} w={'20px'} h={'20px'} borderRadius={'sm'} py={'5px'}  color={select?'tema.bg001':'tema.cor001'} bg={select?'tema.cor002':'tema.cor003'} />} 
                                            <Box as="span" flex='1' lineHeight={'20px'} textAlign='left' color={'tema.cor001'}>
                                                {s.nome}
                                            </Box> 
                                        </Button>
                                    </NavLink>
                                )
                                
                            })} 
                        </Stack> 
                    </AccordionPanel>
                </AccordionItem>
            )
        }else{ 
            var select= false;
            if(page.modulo.replace(/\//g,'') == modulo.toUpperCase().replace(/\//g,'')){
                select = true;
                index = pos;
            } 
            view.push( 
                <AccordionItem   key={'menu'+pos}  border={'0'} color={'tema.cor001'}  p={'0px'}   borderRadius='10px' _expanded={{bg:'red'}}  >
                    <h2 >
                        <NavLink to={modulo} onClick={()=>{
                            cmd()
                        }}>
                            <AccordionButton  p={{base:'5px',md:'10px'}} bg={(select?'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.4)':'transparent' )}  color={(select?'tema.bg001':'tema.cor001' )}   _hover={{bg:'tema.bgh003'}} borderRadius={'lg'} > 
                                {icon==null?null:<Icon as={icon} w={{base:'24px',md:'34px'}} h={{base:'24px',md:'34px'}} borderRadius={'lg'}  p={'6px'}  bg={select?'tema.cor002':'tema.cor003'}/>}
                                <Box as="span" flex='1' lineHeight={{base:'24px',md:'34px'}} textAlign='left' textIndent={'10px'} color={'tema.cor001'} >
                                    {nome}
                                </Box>
                                {sub.length>0?<AccordionIcon   />:null} 
                            </AccordionButton>
                        </NavLink>
                        
                    </h2> 
                </AccordionItem>
            )
        }
        posN++;
    }); 
    return (
        <Box w='100%'   gap={'10px'} > 
            <Accordion   allowToggle={true}  defaultIndex={[index]}   >
                {view} 
            </Accordion> 
        </Box>
    )
}
export default MenuGame;