import { useEffect, useState } from "react";
import { GP, cat, esp, jog, srvTime } from "../../../conn/WebPainel";
import { Box, Button, Flex, Icon, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react"; 
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import BoxGame from "./BoxGame";
import { motion } from "framer-motion";
import ModalGamePlay from "./ModalGamePlay";

const ListaJogos = function(props){
    var flags = {}
    getCountries().forEach((flag)=>{
        flags[getCountryCallingCode(flag)]=flag;
    });
    const {bgT='tema.cor002',aovivo= false, titulo=''}=props
    const [reload,setReload] = useState(0);   
    const [viewGame,setViewGame] = useState(false);   
    const [game,setGame] = useState(null);   
    
    const [width, setWidth] = useState(window.innerWidth);
    let lista = null; 
    let jogos = null; 
    console.log(game);
    useEffect(()=>{
        var inter = setInterval(()=>{ 
            if(typeof GP.provedores!=='undefined' && typeof GP.games!=='undefined'){
                if( reload!=(GP.provedores.up+GP.games.up)){
                    setReload((GP.provedores.up+GP.games.up));
                }
                 
            } 
        },300)
        return ()=>{
            clearInterval(inter);
        }
    },[reload])
    if(typeof GP.provedores!='undefined' && typeof GP.games!='undefined'){ 
        lista =Object.values(GP.provedores.dados); 
        lista=lista.sort((a, b)=>{  
            return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0; 
        });
        jogos = [];
        var add = true;
       
        Object.values(GP.games.dados).forEach((j)=>{
            add = true;  
           if(add){ 
                jogos.push(j);
           }
        })
        jogos.sort((a, b)=>{  
            return a.data < b.data ? -1 : a.data > b.data ? 1 : 0; 
        }); 
    }

    
    return (
        <>
            <Stack  borderRadius={'lg'}   gap={0}>
                <Flex  p={'5px 0px'} w={'100%'}   align={'center'} justify={'space-between'} borderTopRadius={'lg'}>
                    <Text as='h2'  fontWeight={'bold'} color={'tema.cor001'} textShadow={'2xl'} fontFamily={'Barlow'} textTransform={'uppercase'} fontSize={'24px'}>{titulo}</Text> 
                </Flex> 
                <Box w='100%' maxH={'60vh'}   p='5px 0px' gap={'5px'}>  
                {jogos==null?
                        <SimpleGrid columns={{base: 3,  md: 2, md: 3, lg: 4,'2xl':6}} width={'100%'} h={'max-content'}  gap={'20px'}>
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                            <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                        </SimpleGrid> 
                    :jogos.length>0?
                        <SimpleGrid columns={{base: 3,  md: 2, md: 3, lg: 4,'2xl':6}} width={'100%'} h={'max-content'}  gap={'20px'}>
                             {
                                jogos.map((j,i)=>{
                                    return (
                                        <Stack as={motion.button} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} key={'jogo'+i} w={'100%'} h={'auto'} bg={'transparent'}  p={'0'} >
                                            <BoxGame jogo={j} flags={flags} onClick={()=>{
                                                setGame(j);
                                                setViewGame(true);
                                                console.log('clicou')
                                            }}/> 
                                        </Stack> 
                                    )
                                })
                             }
                             <ModalGamePlay open={viewGame} onClose={()=>{
                                setViewGame(false);
                            }} game={game}
                            />
                        </SimpleGrid>:
                        <Stack   w={'100%'} h={'auto'} py={'10px'}  borderRadius={'lg'} p={'20px'} bg={'tema.bg002'}  >
                            <Text color={'tema.cor002'}>Nenhum jogo no momento</Text>
                        </Stack>
                }
                
                    
                        {/* {
                            jogos==null? 
                                <>
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                </>
                            :
                            jogos.length>0?
                            jogos.map((j,i)=>{
                                return (
                                    <Stack as={motion.button} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} key={'jogo'+i} w={'100%'} h={'auto'} bg={'transparent'}  p={'0'} >
                                        <BoxGame jogo={j} flags={flags}/> 
                                    </Stack> 
                                )
                            }):
                            <Stack   w={'100%'} h={'auto'} py={'10px'} ={{base: 3,  md: 2, md: 3, lg: 4,'2xl':6}} borderRadius={'lg'} p={'0'} >
                                <Text color={'tema.cor002'}>Nenhum jogo no momento</Text>
                            </Stack>
                                     
                        }    */}
                </Box>
            </Stack>
        </>

    )
}
export default ListaJogos;