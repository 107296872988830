import { Box, FormControl, FormErrorMessage, FormLabel,Icon,InputLeftElement,Select } from "@chakra-ui/react";
import GradientBorder from "../../VisionUI/GradientBorder";

const SelectCampo =function(props){
    const {titulo='',icone='',error='',w='100%',tipo = '',name='',placeholder='',value=[],onChange=()=>{}}=props
    return  (
        <FormControl position={'relative'} isInvalid={error==''?false:true}   py={'5px'}  >
            <FormLabel color={'tema.cor001'}  lineHeight={'16px'} pl={'5px'}   mb={'2px'}>{titulo}</FormLabel>
            <GradientBorder  mb='10px' w={{ base: "100%"}}   borderRadius='20px'> 
                {icone==''?'': 
                        <Icon zIndex={2} as={icone} boxSize={5}  color="tema.cor003" position={'absolute'} left={'15px'}/>  
                    } 
                <Box overflow={'hidden'} borderRadius='20px' w={'full'}>
                    <Select  
                        size={ {base:'sm',md:'lg'}}
                        zIndex={1} 
                        textIndent={icone==''?'':'2rem'} 
                        placeholder={placeholder}
                        bg={'tema.bg001'} 
                        border={0} 
                        fontSize={'18px'}
                        name={name}
                        color={'tema.cor001'}
                        _placeholder={{
                            color: 'gray.300' 
                        }} 
                        sx={{
                            'option': {
                              background: 'black',
                              color: 'white' 
                            }
                        }} 
                        onChange={onChange}
                        
                    >
                        {value.map((op)=>{
                            return <option key={name+''+op.valor} value={op.valor}>{op.nome}</option>
                        })} 
                    </Select>  
                </Box>
                 
            </GradientBorder>
            <FormErrorMessage  ml={'5px'} w={'full'} position={'absolute'} left={'0px'} bottom={'2px'} lineHeight={'10px'}>{error}</FormErrorMessage>
        </FormControl>
    );

}
export default SelectCampo;