import { Box, Center, Flex, Grid, GridItem, Image, Img, SimpleGrid, Spacer, Stack, Text, color } from "@chakra-ui/react";
import { cfg } from "../../../dados/dados";
import { Bt1 } from "../../Button/ButtonView";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { hexToRGB } from "../../../Function";
const SlideHome = (props)=>{
    const {dados}=props; 
    const [ps,setPs] = useState(0);
    const [bar,setBar] = useState(0);
    useEffect(()=>{
        if(bar==0){
            setTimeout(()=>{ 
                setBar(100)
            });
        }  
    },[bar])
    useEffect(()=>{
        setBar(0);
        
        setTimeout(()=>{
            proximoSlide();
        },slide[ps].time);
        var sOb = boxSlide.current.querySelectorAll('.boxHomeSlide'); 
        sOb.forEach(element => {
            element.style.opacity = 0.2;
        });
        sOb[ps].style.opacity = 1;  
        var center = window.innerWidth*0.5 - sOb[ps].offsetWidth/2 -5
        boxScroll.current.scrollBy({
            left: sOb[ps].offsetLeft-boxScroll.current.scrollLeft ,
            top: 0,
            behavior: 'smooth'
        }) 
    },[ps]); 

    const slide =[ 
        { 
            titulo      :   '',
            descricao   :   "",
            time        :   8000,
            link        :   "/servicos/website-modelos/",
            linkTitulo  :   "Fazer Recarga",
            img         :   'slideHome0001.png',
            cor:{
                bg:'transparent',
                btbg1:'gdGreen.500',
                btbg2:'transparent',
                btclr1:'transparent', 
                btclr2:'transparent',
                
            }
        },
        { 
            titulo      :   'RODADA DA SORTE',
            descricao   :   "Apostas grátis, rodadas grátis e fichas douradas todos os dias!",
            time        :   8000,
            link        :   "/servicos/website-modelos/",
            linkTitulo  :   "Ver Agora",
            img         :   'slideHome0002.png',
            cor:{
                bg:'#2B1512',
                btbg1:'#000000',
                btbg2:'#222222',
                btclr1:'#ffffff', 
                btclr2:'#ffffff',
                
            }
        },
        { 
            titulo      :   'INDICAÇÃO PREMIADA',
            descricao   :   "Indique um amigo e ganhe Rs 10,00",
            time        :   8000,
            link        :   "/servicos/website-modelos/",
            linkTitulo  :   "Ver Agora",
            img         :   'slideHome0003.png',
            cor:{
                bg:'#11358B',
                btbg1:'#000000',
                btbg2:'#222222',
                btclr1:'#ffffff', 
                btclr2:'#ffffff',
                
            }
        } 
    ];
    let boxSlide    =  useRef();
    let boxScroll   =  useRef(); 
 

    function proximoSlide(){
        if(typeof slide[ps+1] == 'undefined'){
            setPs(0);
        }else{
            setPs(ps+1);
        }
    } 
    function voltarSlide(){
        if(typeof slide[ps-1] == 'undefined'){
            setPs(slide.length-1);
        }else{
            setPs(ps-1);
        }
    }  
    return (
        <Flex  h={ {base:'auto'}}  direction={"column"}    w={{base:'100%'}}  pos={'relative'} className="boxScroll" align={'center'} justify={'center'} >
            <Box ref={boxScroll} w={'100%'} overflowX={'hidden'}   h={{base:'100%'}} transition={'3s'}>
                <Grid ref={boxSlide} w={((slide.length)*100)+'%'}   templateColumns= {{base :'repeat('+(slide.length)+', 1fr)'}}   direction={'row'}  gap={'0px'}  >
                    {
                        slide.map((s,id)=>{   
                            return (
                                <GridItem  key={'slideHome'+id}     color={'tema.cor001'}  colSpan={1}  borderRadius={'2xl'}     textAlign={'center'} p={'0'} >
                                    <NavLink to={s.link} w={'100%'} > 
                                        <Box className="boxHomeSlide"    position={'relative'} w={'100%'}    borderRadius={'2xl'} overflow={'hidden'}> 
                                            <Image   zIndex={1} top={'0px'}    w={'100%'}   right={'0px'}   src={"/assets/"+cfg.site+"/img/slideHome/"+s.img} />
                                        </Box> 
                                    </NavLink> 
                                </GridItem>
                                
                            )
                        })
                    } 
                </Grid>
                {/* <Grid   py='5px'  w={'100%'}     bg='red'  templateColumns= {{base :'repeat(1fr, 1)'}}  gap='10px' >
                 
                    <GridItem  bg={'blue'} h={'20px'} w={'90%'}  color={'tema.cor001'}  colSpan={1}     textAlign={'center'} p={'0'} >

                    </GridItem>
                    <GridItem  bg={'blue'} h={'20px'} w={'90%'}  color={'tema.cor001'}  colSpan={1}     textAlign={'center'} p={'0'} >

                    </GridItem>
                </Grid> */}
                {/* <Flex ref={boxSlide} w={'max-content'}  minW={'100%'} bg='red'  direction={'row'}  gap={'20px'} >
                      
                </Flex> */}
            </Box>
            {/* 
                
            </Box>  */}
        </Flex>
    );
} 
export default SlideHome;