import { Avatar, AvatarGroup, Box, Button, Flex, Icon, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"; 
import { RiBarChart2Fill } from "react-icons/ri";

import { FiMonitor } from "react-icons/fi";
import { TbSoccerField } from "react-icons/tb";
import { hexToRGB } from "../../../Function";
import { cfg } from "../../../dados/dados";
import QuadraMakerBeachTennis from "./QuadraMakerBeachTennis";
import { FaExchangeAlt, FaList } from "react-icons/fa";
import { useEffect, useRef, useState } from "react"; 
import GradientBorder from "../../VisionUI/GradientBorder";
import { PiUserSwitchBold } from "react-icons/pi";
import ListaAoVivoMaker from "./ListaAoVivoMaker";
import { GM, sendForm } from "../../../conn/WebGame";
import BoxWindow from "../../BoxWindow/BoxWindow";
const PlayAoVivoMaker = function(){  
    const play  = useRef();
    
    const [alerta,setAlerta] = useState({}); 
    const [inverter,setInverter] = useState(false);
    //dados tabela
    if(typeof GM.selecaoAoVivo == 'undefined'){
        GM.selecaoAoVivo={up:0,dados:{}}; 
    }  
    const [reload,setReload]= useState(GM.selecaoAoVivo.up)
    useEffect(()=>{   
        var interV = setInterval(()=>{
            if(reload!=GM.selecaoAoVivo.up){
                setReload(GM.selecaoAoVivo.up); 
            }  
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);
    //dados tabela
    if(typeof GM.marcacaoAoVivo == 'undefined'){
        GM.marcacaoAoVivo={up:0,dados:{}}; 
    }  
    const [reloadG,setReloadG]= useState(GM.marcacaoAoVivo.up)
    useEffect(()=>{   
        var interV = setInterval(()=>{
            if(reloadG!=GM.marcacaoAoVivo.up){
                setReloadG(GM.marcacaoAoVivo.up); 
            }  
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reloadG]); 

    useEffect(()=>{   
    },[inverter]);
    var fase = 0;
    let jogo = {}; 
    let game = {}; 
    let faseView = {
        titulo      :   'Selecione a partida:',
        table       :   [],
        tableIcon   :   [],
        cmd         :   [],
        placar      :   null,
        sets        :   null
    }
    const  num= {
        0:'0',
        1:'15',
        2:'30',
        3:'40',
        4:'G',
    }
    if(Object.keys(GM.selecaoAoVivo.dados).length>0 && Object.keys(GM.marcacaoAoVivo.dados).length>0){
        jogo = Object.values(GM.selecaoAoVivo.dados)[0];
        game = Object.values(GM.marcacaoAoVivo.dados)[0];  
        if(game.gameStart == null){
            fase=1;
        }else if(game.gameStart.acao.length==0){
            fase=2;
        }else if(game.gameStart.acao.length>0){
            fase=3;
        } 
    }  
    
    if(game.setStart!=null){
        console.log(game);
        faseView.placar = (
            <Flex direction={'row'} flex={'1'} color={'tema.cor001'}> 
                <Flex w={'100%'} h={'30px'} align={'center'} justify={'space-between'} >
                     {jogo.jg02== null?
                        <Text  w={'100%'} textAlign='right' px={'10px'} pt={'2px'} fontSize={'14px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'}  >{jogo.jg01}</Text>
                        :
                        <Flex w={'100%'} direction={'column'}>
                            <Text  w={'100%'} textAlign='left' px={'10px'} pt={'0px'} fontSize={'10px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'}  >{jogo.jg01}</Text>
                            <Text  w={'100%'} textAlign='left' px={'10px'} pt={'0px'} fontSize={'10px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'}  >{jogo.jg02}</Text>
                        </Flex>
                    }
                    <Text w='30px'   bg={'rgba('+hexToRGB(cfg.theme.cores.bg002)+' ,0.3)'} pt='2px'>{game.setStart.tipo==1?num[game.setStart.placar.c]:game.setStart.placar.c}</Text>
                </Flex>
                <Flex w={'100%'} h={'30px'} align={'center'} justify={'space-between'} >
                    <Text w='30px'   bg={'rgba('+hexToRGB(cfg.theme.cores.bg003)+' ,0.6)'} pt='2px'>{game.setStart.tipo==1?num[game.setStart.placar.v]:game.setStart.placar.v}</Text>
                    {jogo.jg04== null?
                        <Text  w={'100%'} textAlign='right' px={'10px'} pt={'2px'} fontSize={'14px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'}  >{jogo.jg03}</Text>
                        :
                        <Flex w={'100%'} direction={'column'}>
                            <Text  w={'100%'} textAlign='right' px={'10px'} pt={'0px'} fontSize={'10px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'}  >{jogo.jg03}</Text>
                            <Text  w={'100%'} textAlign='right' px={'10px'} pt={'0px'} fontSize={'10px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'}  >{jogo.jg04}</Text>
                        </Flex>
                    } 
                </Flex>
            </Flex>
        )
    }
    try {
        faseView.sets = <>
            <Spacer/>
            <Flex direction={'row'} bg='tema.bg003' p={'2px'} gap={'2px'}>
                <Stack direction={'column'} gap={'0'}  fontSize={'12px'} >
                    <Text lineHeight={'12px'} fontSize={'7px'} px={'2px'}>SET 1</Text>
                    <Text lineHeight={'12px'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)'}>{game.setPla.set1.c}</Text>
                    <Text lineHeight={'12px'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.5)'}>{game.setPla.set1.v}</Text>
                </Stack> 
                <Stack direction={'column'} gap={'0'}  fontSize={'12px'} >
                    <Text lineHeight={'12px'} fontSize={'7px'}  px={'2px'}>SET 2</Text>
                    <Text lineHeight={'12px'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)'}>{game.setPla.set2.c}</Text>
                    <Text lineHeight={'12px'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.5)'}>{game.setPla.set2.v}</Text>
                </Stack> 
                <Stack direction={'column'} gap={'0'}  fontSize={'12px'} >
                    <Text lineHeight={'12px'} fontSize={'7px'}  px={'2px'} >SET 3</Text>
                    <Text lineHeight={'12px'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)'}>{game.setPla.set3.c}</Text>
                    <Text lineHeight={'12px'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.5)'}>{game.setPla.set3.v}</Text>
                </Stack> 
            </Flex>
        </>;
    } catch (error) {
        
    }
    

    switch (fase) {
        case 0:
            faseView.titulo = 'Selecione a o partida:'
            faseView.table = <TabPanel  p={'0px'} minH={'full'} position={'relative'}> 
                <Flex bg={'rgba('+hexToRGB(cfg.theme.cores.bg002)+',0.5)'}   backdropFilter='blur(10px) hue-rotate(90deg)' zIndex={10} position={'absolute'}  w={'100%'} h='100%' align={'center'} justify={'center'}>
                    <ListaAoVivoMaker />
                </Flex>
                <QuadraMakerBeachTennis fase={0} inverter={inverter} />
            </TabPanel>
            faseView.tableIcon = <Tab> 
                <Icon as={FaList} fontSize={'22px'}/>
            </Tab>
        break; 
        case 1:
            if(game.setStart == null){
                faseView.titulo = 'Selecione a o campo para iniciar a partida:'; 
            }else{
                faseView.titulo = 'Selecione a o campo para iniciar a game:';
            } 
            faseView.cmd = <Button display={fase==1?'flex':'none'} h={'30px'} colorScheme="blackAlpha" onClick={()=>{
                setAlerta({load:true})
                setTimeout(()=>{
                    setAlerta({
                        fechar:true,
                        titulo:'Atenção',
                        size:'md',
                        mensagem:'Deseja realmente iniciar a '+(game.setStart == null?'Partida':'Game')+' <br/><font color="'+cfg.theme.cores.cor003+'"><b>'+jogo.jg01+'</b></font> '+(jogo.jg02!=null?' e <font color="'+cfg.theme.cores.cor003+'"><b>'+jogo.jg02+'</b></font>':null)+' <br/>vs<br/> <font color="'+cfg.theme.cores.cor002+'"><b>'+jogo.jg03+'</b></font> '+(jogo.jg04!=null?' e <font color="'+cfg.theme.cores.cor002+'"><b>'+jogo.jg04+'</b></font>':null)+'?', 
                        botao:[
                            {
                                nome:'SIM',
                                color:'green',
                                cmd:()=>{
                                    sendForm({modulo:'operador',cmd:'iniciarGame',form:{inverter:inverter}}); 
                                }
                            },
                            {
                                nome:'NÃO',
                                color:'red'
                            }
                        ]
                    });
                })
            }} >INICIAR {(game.setStart == null?'SET':'GAME')}</Button>
            
            faseView.table = <TabPanel key={'painel01'}  p={'0px'} minH={'full'} position={'relative'}> 
                <Flex bg={'rgba('+hexToRGB(cfg.theme.cores.bg002)+',0.5)'}   backdropFilter='blur(10px) hue-rotate(90deg)' zIndex={10} position={'absolute'}  w={'100%'} h='100%' align={'center'} justify={'center'}>
                    <Button  onClick={()=>{ 
                        if(inverter){
                            setInverter(false);
                        }else{
                            setInverter(true);
                        }
                    }} position={'absolute'} left={'50%'} top={'50%'} minH={'20px'} h={'20px'} bg={'tema.bg001'} fontSize={'10px'} leftIcon={<FaExchangeAlt color="tema.cor001" />} transform={'translateX(-50%) translateY(-50%)'} p={'5px 7px'} variant={'solid'} colorScheme="blackAlpha">MUDAR CAMPO</Button>
                    <GradientBorder w='90%' borderRadius='20px'> 
                        {!inverter?
                            <Stack w='100%'  direction={'column'} p={'0px'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.7)'} borderRadius={'20px'} gap={'3px'}>
                                <Flex bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)'} h={'45px'} pb={'5px'} direction={'row'} align={'center'} justify={'flex-start'} borderRadius={'20px 20px 0 0'}>
                                    <AvatarGroup size='md' max={2} mt='-20px' ml='-10px'>
                                        <Avatar name={jogo.jg01} src={'/p/'+jogo.img01} /> 
                                        {jogo.jg02!=null?<Avatar name={jogo.jg02} src={'/p/'+jogo.img02} />:null}  
                                    </AvatarGroup>
                                    <Stack gap={'0px'} h={'100%'} pb={'4px'} fontSize={'14px'} textAlign={'left'}  pl='10px' color={'tema.cor001'} align={'center'} justify={'center'} >
                                        <Text h={'14px'}  w={'100%'}>{jogo.jg01}</Text>
                                        {jogo.jg02!=null?<Text h={'16px'} w={'100%'}>{jogo.jg02}</Text>:null}
                                        
                                    </Stack>
                                    
                                </Flex> 
                                <Flex bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.5)'} h={'45px'} pt={'5px'}  direction={'row'} justify={'flex-end'} borderRadius={'0 0 20px 20px'}>
                                    <Stack gap={'0px'} h={'100%'} pb={'4px'} fontSize={'14px'} color={'tema.cor001'} pr='10px' align={'baseline'} justify={'center'} textAlign={'right'}>
                                        <Text h={'14px'}  w={'100%'}>{jogo.jg03}</Text>
                                        {jogo.jg04!=null?<Text h={'16px'} w={'100%'}>{jogo.jg04}</Text>:null}
                                    </Stack> 
                                    <AvatarGroup size='md' max={2} mb='-20px' mr='-10px'> 
                                        <Avatar name={jogo.jg03} src={'/p/'+jogo.img03} /> 
                                        {jogo.jg04!=null?<Avatar name={jogo.jg04} src={'/p/'+jogo.img04} />:null}  
                                    </AvatarGroup> 
                                </Flex>
                            </Stack> 
                        :
                            <Stack w='100%' direction={'column'} p={'0px'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.7)'} borderRadius={'20px'} gap={'3px'}>
                                <Flex bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)'} h={'45px'} pb={'5px'} direction={'row'} align={'center'} justify={'flex-end'} borderRadius={'20px 20px 0 0'}>
                                    <Stack gap={'0px'} h={'100%'} pb={'4px'} fontSize={'14px'} textAlign={'right'}  pr='10px' color={'tema.cor001'} align={'center'} justify={'center'} >
                                        <Text h={'14px'}  w={'100%'}>{jogo.jg01}</Text>
                                        {jogo.jg02!=null?<Text h={'16px'} w={'100%'}>{jogo.jg02}</Text>:null}
                                        
                                    </Stack>
                                    <AvatarGroup size='md' max={2} mt='-20px' mr='-10px'>
                                        <Avatar name={jogo.jg01} src={'/p/'+jogo.img01} /> 
                                        {jogo.jg02!=null?<Avatar name={jogo.jg02} src={'/p/'+jogo.img02} />:null}  
                                    </AvatarGroup> 
                                </Flex> 
                                <Flex bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.5)'} h={'45px'} pt={'5px'}  direction={'row'} justify={'flex-start'} borderRadius={'0 0 20px 20px'}>
                                    <AvatarGroup size='md' max={2} mb='-20px' ml='-10px'>
                                        <Avatar name={jogo.jg03} src={'/p/'+jogo.img03} /> 
                                        {jogo.jg04!=null?<Avatar name={jogo.jg04} src={'/p/'+jogo.img04} />:null}  
                                    </AvatarGroup>
                                    <Stack gap={'0px'} h={'100%'} pb={'4px'} fontSize={'14px'} color={'tema.cor001'} pl='10px' align={'baseline'} justify={'center'} textAlign={'left'}>
                                        <Text h={'14px'}  w={'100%'}>{jogo.jg03}</Text>
                                        {jogo.jg04!=null?<Text h={'16px'} w={'100%'}>{jogo.jg04}</Text>:null}
                                    </Stack>  
                                </Flex>
                            </Stack> 
                        }
                        
                    </GradientBorder>
                    
                </Flex>
                <QuadraMakerBeachTennis fase={0} inverter={inverter} />
            </TabPanel> 
        break;
        case 2:case 3:
            faseView.titulo = 'Selecione quem vai sacar:'; 
            faseView.table = [
                <TabPanel  key={'painel01'}  p={'0px'}  > 
                    <QuadraMakerBeachTennis jogo={jogo} game={game} fase={fase} inverter={inverter} />
                </TabPanel>,
                <TabPanel  key={'painel02'}  p={'0px'}  > 
                    lista
                </TabPanel>
            ]   
            faseView.tableIcon  =[
                <Tab  key={'painelbt01'}  display={(fase>0?'grid':'none')}>
                    <Icon as={TbSoccerField} fontSize={'26px'}/>
                </Tab>,
                <Tab   key={'painelbt02'}  display={(fase>0?'grid':'none')}> 
                    <Icon as={FaList} fontSize={'22px'}/>
                </Tab> 
            ]                            
        break; 
    }
     
    

    return ( 
        <Box w={{base:'full', sm:'580px'}} borderRadius='10px'  bg='tema.bg002' p={{base:'10px 0',sm:'10px'}}  >
            <Stack direction={'row'}  flex='1' h='40px' pb={'10px'} px={'10px'}  color={'tema.cor001'} align={'center'} justify={'center'}  textAlign='left'>
                <Icon as={TbSoccerField}/> 
                <Text  >MAKER AO VIVO</Text><Spacer/>
                {faseView.cmd}
            </Stack>
            <Stack  direction={'row'} w={'full'} h={{base:'30px'}} pb={'1px'} pl={'1px'} pr='1px' gap={'0px'} bg={'tema.bg001'} align={'center'} justify={'center'} color={'tema.cor001'} >
                <Text w={'100%'} color={'tema.cor001'} textAlign={'center'}>{faseView.titulo}</Text>
            </Stack>
            {faseView.placar}
            <Tabs>
                <Stack flex={1} h={'max-content'}   w={'full'}> 
                    <TabPanels >
                        {faseView.table} 
                    </TabPanels>
                </Stack>
                <Stack w={'full'} direction={'row'} h={{base:'40px'}} bg={'tema.bg001'} align={'center'} justify={'center'}  color={'tema.cor001'} >
                    <TabList borderBottom={'0px'}> 
                        {faseView.tableIcon}  
                    </TabList>  
                    {faseView.sets}
                </Stack> 
            </Tabs>  
            <BoxWindow param ={alerta} />
        </Box>
        // <Box w={{base:'380px', sm:'580px'}} borderRadius='10px'  bg='tema.bg002' p={'10px'} >
            
           
                
        //             {fase>2?
                          
        //             :
        //             null}
        //             
                     
        //         </Stack>
            
            
    )
};
export default PlayAoVivoMaker;