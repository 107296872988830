import { Flex, GridItem, SimpleGrid, Stack, Text } from "@chakra-ui/react"; 
import { cfg } from "../../dados/dados";  
const DashboardMaker=(props)=>{  
    return <> 
        <Flex  direction={{base:'column',lg:'row'}} w={'100%'} minH={'auto'} align={'center'}  pt={'140px'}   px='20px' >
            <Stack  w={'100%'}     gap={'20px'}>
                <Text></Text>
            </Stack>  
        </Flex> 
    </>
}
export default  DashboardMaker;