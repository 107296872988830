import { FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import GradientBorder from "../../VisionUI/GradientBorder";
import { FaEye, FaEyeSlash, FaUser } from "react-icons/fa";
import  InputMask  from 'react-input-mask';  
import { useEffect, useState } from "react";

const InputCampo =function(props){
    const {titulo='',size='lg',icone='',error='',w='100%',tipo = '',name='',placeholder='',bt=null ,onChange=()=>{},onFocus=()=>{}}=props

    let mascara = false;
    const [view,setView] = useState(null);
    useEffect(()=>{

    },view);
    var config = {
        type: 'text',
        mask:   false,  
        formatChars: {'#': '[A-Za-z0-9@._-]'} 
    }
    switch(tipo){
        case 'user':
            config.type = 'text';
            config.mask = true;
            config.formatChars= {'#': '[A-Za-z0-9@._-]'} 
        break
        case 'email':
            config.type = 'text';
            config.mask = true;
            config.formatChars= {'#': '[A-Za-z0-9@._-]'} 
        break
        case 'password':
            if(view == null){
                setView(false);
            }else{
                if(view){
                    config.type = 'text';
                }else{
                    config.type = 'password';
                }
            }
        break 
    } 
    return  (
        <FormControl position={'relative'} isInvalid={error==''?false:true}   pb={'5px'}  >
            <FormLabel color={'tema.cor001'}  lineHeight={'16px'} pl={'20px'}   mb={'7px'}>{titulo}</FormLabel>
            <GradientBorder  mb='10px' w={{ base: "100%"}} borderRadius='20px'> 
                <InputGroup size={size}>
                    {icone==''?'':
                        <InputLeftElement width='3rem'>
                            <Icon as={icone} boxSize={5}  color="tema.cor002"/> 
                        </InputLeftElement>
                    } 
                    {config.mask?
                        <Input
                        pl={icone==''?'':'3rem'}
                        pr={view==null?'':'3rem'}
                        as={config.mask?InputMask:null}
                        mask={'####################################################################################################'}  
                        formatChars= {{'#': '[A-Za-z0-9@._-]'}}  
                        maskChar= ''
                        alwaysShowMask= {true} 
                        placeholder={placeholder}
                        borderRadius='20px'
                        bg={'tema.bg001'}
                        type={config.type}
                        border={0}
                        name={name}
                        color={'tema.cor001'}
                        onChange={onChange}
                        onFocus={onFocus}
                        _placeholder={{
                            color: 'gray.300',
                        }}
                    /> 
                    :
                        <Input
                            pl={icone==''?'':'3rem'}
                            pr={view==null?'':'3rem'}   
                            placeholder={placeholder}
                            borderRadius={bt==null?'20px':'20px 0 0 20px' }
                            bg={'tema.bg001'}
                            type={config.type}
                            border={0}
                            name={name}
                            onChange={onChange}
                            onFocus={onFocus}
                            color={'tema.cor001'}
                            _placeholder={{
                                color: 'gray.300',
                            }}
                        /> 
                    }
                    
                    {view==null?'':
                        view==true?
                        <InputRightElement width='3rem' onClick={()=>setView(false)}> 
                            <Icon  fontSize={'22px'} ml='5px' as={FaEye} color={'tema.cor002'}/> : 
                        </InputRightElement>
                        :
                        <InputRightElement width='3rem' onClick={()=>setView(true)}>
                            <Icon fontSize={'22px'}  as={FaEyeSlash} color={'tema.cor002'}/> 
                        </InputRightElement>
                    } 
                    
                {bt!=null?bt:null}
                </InputGroup>
            </GradientBorder>
            <FormErrorMessage  ml={'5px'} w={'full'} position={'absolute'} left={'0px'} bottom={'2px'} lineHeight={'10px'}>{error}</FormErrorMessage>
        </FormControl>
    )
}
export default InputCampo;