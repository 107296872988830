

import { BrowserRouter, Routes, Route} from "react-router-dom"
import { Theme } from "./Theme"
export const Router = () => {
    return (
        <BrowserRouter> 
            <Routes> 
                <Route path="/*" element={<Theme/>} />   
            </Routes>
       </BrowserRouter>
    )
}