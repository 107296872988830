import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Grid, GridItem, SimpleGrid, Spacer, Spinner, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { hexToRGB, parseValor } from "../../Function";
import { cfg } from "../../dados/dados";
import { useEffect, useRef, useState } from "react";
import Input from "../Input";
import BoxFiltro from "../BoxWindow/BoxFiltro";

const ListaResponsiva = function(props){
    const {titulos=[],dados=[],max=10,campos=[]} = props
    const [reload,setReload] = useState([]);
    const [filtro,setFiltro] = useState([]);
    const [ordem,setOrdem] = useState([]);
    const [sort,setSort] = useState([]);

    const [page,setPage] = useState(0);
    useEffect(()=>{ },[ordem,sort,page,filtro]);
    
    let numC = 0;
    titulos.forEach(el => {
        numC+=el.w;
    }); 
    let pags =[]; 
    var dFiltro = [];
    
    if(dados !== null){
        dados.forEach((d)=>{ 
            var add = true; 
            if(filtro.length>0){
                add = false;
                filtro.forEach((f)=>{ 
                    if((d.campos[f.campo].toString().toUpperCase()).includes(f.valor.toString().toUpperCase())){
                        add=true;
                    }
                })
            }
            if(add){
                dFiltro.push(d)
            }
            
        }); 
        var nPag = parseInt(parseValor(dFiltro.length/max).p);
        if(parseValor(dFiltro.length/max).d>0){
            nPag++;
        }  
        var add = false;
        for(var n=0;n<nPag;n++){
            add = false; 
            switch (page) {
                case 0:case 1: case 2:
                    if(n==0 || n ==1 || n==2 ||  n==3 || n ==(nPag-1)){
                        add =true;
                    }
                break;
                default:
                    if(page==nPag-1 ||page==nPag-2 ||page==nPag-3 ){ 
                         
                        if(n==0 || n==nPag-1 ||n==nPag-2 ||n==nPag-3 || n==nPag-4){
                            add =true;
                        }
                    }else{ 
                        if(n==0 || n==page-1  ||n==page  ||n==page+1  || n ==(nPag-1)){
                            add =true;
                        }
                    }
                break;
             
            }  
            pags.push(add);
        } 
    } 
    //filtro 
    const fundoBG = 'linear-gradient(127.09deg, rgba(0, 0, 0, 0.94) 19.41%, rgba(0, 0, 0, 0.49) 76.65%) border-box;';
    const fundoBGM = 'linear-gradient(180deg, rgba('+hexToRGB(cfg.theme.cores.cor003)+', 0.44) 10.41%, rgba(0, 0, 0, 0.3) 76.65%) border-box;';
    var tPage = (page>nPag-1)?nPag-1:page;
    return (
        <>  
            <Flex gap={'5px'} w={'100%'} align={'flex-end'} justify={'flex-end'} > 
                <BoxFiltro campos={campos} filtro={filtro} setFiltro={(e)=>{
                    
                    setFiltro(e);
                    setReload({});
                }} btT='Filtro'/><Spacer/>
                {pags.length>0?
                    <Flex gap={'5px'} py={'5px'}>
                        { 
                            pags.map((ativo,p)=>{
                                if(ativo){
                                    return <Button onClick={()=>{ setPage(p) }} key={'pag'+p} w={'32px'} h={'32px'} minW={'auto'}  variant={'outline'} colorScheme={(p==page?"orange":"whiteAlpha")} borderWidth={'2px'} borderRadius={'xl'} p='0px' >{(p+1)}</Button>
                                }else{
                                    return null
                                } 
                            })
                        }
                    </Flex> 
                : null}
            </Flex> 
            
            <Flex w={'100%'} textShadow={'xl'} mt={'10px'} direction={'column'} bg={{base:'transparent',md:fundoBG}}  borderRadius={{base:'0',md:'xl'}} overflow={{base:'initial',md:'hidden'}}> 
                {dados==null|| dFiltro.length==0?null:
                    <Grid display={{base:'none',md:'grid'}} py='10px'  w={'100%'} gap={'1px'} px={'1px'}  templateColumns= {{base :'repeat('+numC+', 1fr)'}}  >
                        {(titulos.length>0?titulos.map((t,i)=>{
                            return (
                                <GridItem key={'titulo'+i} maxW={'100%'}  w={'100%'} color={'tema.cor001'}  colSpan={{base:1,md:t.w}} noOfLines={'1'}    textAlign={'center'}>
                                    {t.nome}
                                </GridItem>
                            )
                        }):null)} 
                    </Grid> 
                }
                {
                    (dados == null)?
                        <Stack direction={'row'} py='20px' lineHeight={'auto'} minH={'auto'} w={'100%'} fontWeight={'bold'} color={'tema.cor002'} align={'center'} justify={'center'}>
                            <Spinner color='tema.cor003' /> <Text  ml='10px'>Carreganado dados... aguarde um momento!</Text>
                        </Stack>
                    :
                    dFiltro.length==0?
                        <Stack direction={'row'} py='20px' lineHeight={'auto'} minH={'auto'} w={'100%'} fontWeight={'bold'} color={'tema.cor002'} align={'center'} justify={'center'}>
                            <Text  ml='10px'>Nenhum resultado encontrado!</Text>
                        </Stack>
                    :
                    dFiltro.map((v,i)=>{
                        if(i>=(max*tPage) && i<(tPage*max+max))
                        return(
                            <Grid  key={'linha'+i}  pb={{base:'0px',md:'1px'}} mb={{base:'20px',md:'1px'}}   gap={'1px'} px={'1px'}    templateColumns= {{base :'repeat(2,1fr)',md :'repeat('+numC+', 1fr)'}}  borderRadius={{base:'xl',md:'0'}}  bg={{base:fundoBGM,md:'rgba(255,255,255,0.1)'}} backdropBlur={'30px'}   >
                                <GridItem display={{base:'flex',md:'none'}} mt={'1px'} key={'campo'+i+'_nomeT'} maxW={'100%'} mb={{base:'10px',md:'1px'}}  color={'tema.cor001'}  w={'100%'} overflow={'hidden'}   colSpan={{base:2,md:1}} alignItems={'center'} justifyItems={'center'}   >
                                    <Stack py={{base:'10px',md:'10px'}} lineHeight={'auto'} minH={'auto'} w={'100%'} fontWeight={'bold'} color={'tema.cor002'}>
                                         {v['nome']} 
                                    </Stack> 
                                </GridItem>
                                {(titulos.length>0?titulos.map((t,i)=>{
                                    switch (t.campo) {  
                                        default:
                                            return (
                                                <GridItem  w={'100%'} display={{base:(t.campo =='nome'?'none':'flex'),md:'flex'}} mt={'1px'} key={'campo'+i+'_'+t.campo} maxW={'100%'} color={'tema.cor001'}  overflow={'hidden'}   colSpan={{base:1,md:t.w}}  alignItems={{base:'flex-start',md:'center'}} justifyItems={{base:'flex-start',md:'center'}}  >
                                                    <Stack w={'100%'} direction='column'  p='0px' fontWeight={'bold'}  gap={'0px'}>
                                                        <Text    fontSize={'12px'} maxW={'100%'}    display={{base:'flex',md:'none'}} color={'tema.cor002'} m={'0 auto'}>{t.nome}</Text>
                                                        <Box    w={'100%'}> {v[t.campo]}</Box> 
                                                    </Stack> 
                                                </GridItem>
                                            ) 
                                    } 
                                    
                                }):null)} 
                            </Grid> 
                        )
                    })
                }
            </Flex> 
            {dFiltro.length>0? 
                <Flex w={'full'} align={'center'} justify={'flex-end'} px={'5px'} mt={'10px'}>
                    <Text color={'tema.cor001'} opacity={'0.7'}>Resultados de {(max*tPage)+1 } a {((tPage*max+max)<=dFiltro.length)?(tPage*max+max)+1:dFiltro.length} do total de {dFiltro.length}</Text>
                </Flex>
            :null}
            
        </>
    )
}
export default ListaResponsiva;