import { Avatar, Box, Button, Flex, Icon, Img, Stack, Switch, Text } from "@chakra-ui/react"
import { cfg } from "../../../dados/dados";
import { useEffect, useState } from "react";
import { hexToRGB } from "../../../Function";
import GradientBorder from "../../VisionUI/GradientBorder";
import { MdSportsTennis } from "react-icons/md";
import BoxWindow from "../../BoxWindow/BoxWindow";
import { sendForm } from "../../../conn/WebGame";
import { MdOutlineScreenRotation } from "react-icons/md";


const QuadraMakerBeachTennis = function(props){ 
    
    const [alerta,setAlerta] = useState({}); 
    const {jogo={},game={}}=props;
    const [saque,setSaque] = useState({}); 
    const [rotacionar,setRotacionar] = useState(false); 
    useEffect(()=>{ 
      
    },[props,saque,alerta]); 
    // const [alerta,setAlerta] = useState('');  
    const  fase     =   props.fase;
    const  inverter =   typeof game.gameStart!='undefined'?game.gameStart.inverter:false;  
    const  cor1 = inverter?'orange':'blue';
    const  cor2 = inverter?'blue':'orange';
    const  cor1B = inverter?cfg.theme.cores.cor002:cfg.theme.cores.cor003;
    const  cor2B = inverter?cfg.theme.cores.cor003:cfg.theme.cores.cor002;  
    const  time1 = inverter?'C':'V';
    const  time2 = inverter?'V':'C'; 
    
    let viewQd = {
        info:null,
        cmdL :null,
        cmdR :null 
    }
    switch (fase) {
        case 2:
            viewQd.cmdL = (
                <Flex  w={'full'} h={'full'} direction={'column'}>   
                    <Button onClick={()=>{
                        sendForm({modulo:'operador',cmd:'posicaoSaque',form:{tipo:1,time:time2}}); 
                        setSaque({tipo:1,time:time2})
                    }} fontSize={'10px'}  h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'}  transform='rotate(-90deg) translateX(-50%) translateY(-50%)'   position={'absolute'} top={'-22px'}  left={'-28%'}  w={'50%'}  colorScheme={(saque.tipo==1 && saque.time==time2)?'green':cor1} variant={'outline'}>
                        <Text  noOfLines={'1'} pt={'2px'}>SAQUE TOPO</Text>
                    </Button> 
                    <Button onClick={()=>{
                        sendForm({modulo:'operador',cmd:'posicaoSaque',form:{tipo:2,time:time2}}); 
                        setSaque({tipo:2,time:time2})
                    }} fontSize={'10px'} h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'}  transform='rotate(-90deg) translateX(-50%) translateY(-50%)' mb={{base:'-15px',sm:'-22px'}}  position={'absolute'} bottom={'50%'}  left={'-28%'}   w={'50%'}  colorScheme={(saque.tipo==2 && saque.time==time2)?'green':cor1} variant={'outline'}>
                        <Text  noOfLines={'1'} pt={'2px'}>SAQUE BASE</Text>
                    </Button> 
                </Flex>
            )
            viewQd.cmdR = (
                <Flex w={'full'} h={'full'} direction={'column'}  >  
                    <Button onClick={()=>{
                        sendForm({modulo:'operador',cmd:'posicaoSaque',form:{tipo:1,time:time1}}); 
                        setSaque({tipo:1,time:time1})
                    }} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px' transition={'0s'} transform='rotate(90deg) translateX(-50%) translateY(-50%)' mt={{base:'-15px',sm:'-22px'}}  position={'absolute'} top={'50%'}  right={'-28%'}   w={'50%'}  colorScheme={(saque.tipo==1 && saque.time==time1)?'green':cor2} variant={'outline'}>
                        <Text  noOfLines={'1'} pt={'2px'}>SAQUE TOPO</Text>
                    </Button>
                    <Button onClick={()=>{
                        sendForm({modulo:'operador',cmd:'posicaoSaque',form:{tipo:2,time:time1}}); 
                        setSaque({tipo:2,time:time1})
                    }} fontSize={'10px'}  h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'} transform='rotate(90deg) translateX(-50%) translateY(-50%)'   position={'absolute'} bottom={'-22px'}  right={'-28%'}  w={'50%'}  colorScheme={(saque.tipo==2 && saque.time==time1)?'green':cor2} variant={'outline'}>
                        <Text  noOfLines={'1'} pt={'2px'}>SAQUE BASE</Text>
                    </Button>  
                </Flex> 
            )
            viewQd.info = (
                <>
                    <Button   onClick={()=>{
                        if(rotacionar){
                            setRotacionar(false)
                        }else{
                            setRotacionar(true)
                        }
                    }} zIndex={4} left={'0px'} top={'50%'} position={'absolute'} transform=' translateY(-50%)'  colorScheme="green" variant={'solid'} p={'0px'}> <Icon as={MdOutlineScreenRotation}></Icon></Button>
           
                    {Object.keys(saque).length>0?
                        <Button onClick={()=>{
                            if(Object.keys(saque).length>0){ 
                                sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:saque}); 
                                setSaque({})
                            }else{
                                setAlerta({load:true});
                                setTimeout(()=>{
                                    setAlerta({
                                        fechar:true,
                                        titulo:'Atenção',
                                        size:'md',
                                        mensagem:'Selecione uma posição de saque'
                                    });
                                })
                            }
                        }} zIndex={4} left={'50%'} top={'50%'} position={'absolute'} transform=' translateX(-50%) translateY(-50%)'  colorScheme="green" variant={'solid'} leftIcon={<MdSportsTennis/>}>SACOU</Button>
                    
                    :null}
                    <Flex display={fase==2?'flex':'none'}  zIndex={1}  position={'absolute'} left={'0px'} top={'0px'} w={'full'} h={'full'}  >
                        <GradientBorder position={'absolute'} maxW='calc(50% - 10px)' left={'10px'} top={'10px'} borderRadius='10px' h='44px' p='2px' bg='rgba(0,0,0,0.4)' align={'center'} justify={'center'} >
                            <Stack   direction={'row'} h={'40px'} p={'10px'} align={'center'} justify={'center'} borderRadius={'8px'} bg={'rgba('+hexToRGB(cor1B)+' ,0.6)'}>
                                <Avatar size={'lg'} border={'2px solid #ffffff'}  ml="-20px"  name={inverter?jogo.jg03:jogo.jg01} src={'/p/'+(inverter?jogo.img03:jogo.img01)} />
                                <Text color={'tema.cor001'} textAlign={'left'} noOfLines={1}>{inverter?jogo.jg03:jogo.jg01}</Text>
                            </Stack>
                        </GradientBorder>
                            {jogo.jg02!==null?
                                <GradientBorder position={'absolute'} maxW='calc(50% - 10px)' left={'10px'} bottom={'10px'} borderRadius='10px' h='44px' p='2px' bg='rgba(0,0,0,0.4)' align={'center'} justify={'center'} >
                                    <Stack   direction={'row'} h={'40px'} p={'10px'} align={'center'} justify={'center'} borderRadius={'8px'} bg={'rgba('+hexToRGB(cor1B)+' ,0.6)'}>
                                        <Avatar size={'lg'} border={'2px solid #ffffff'}  ml="-20px"  name={inverter?jogo.jg04:jogo.jg02} src={'/p/'+(inverter?jogo.img04:jogo.img02)} />
                                        <Text color={'tema.cor001'} textAlign={'left'} noOfLines={1}>{inverter?jogo.jg04:jogo.jg02}</Text>
                                    </Stack>
                                </GradientBorder>
                            :null 
                        } 
                        <GradientBorder position={'absolute'} right={'10px'} maxW='calc(50% - 10px)'  top={'10px'} borderRadius='10px' h='44px' p='2px' bg='rgba(0,0,0,0.4)' align={'center'} justify={'center'} >
                            <Stack   direction={'row'} h={'40px'} p={'10px'} align={'center'} justify={'center'} borderRadius={'8px'} bg={'rgba('+hexToRGB(cor2B)+' ,0.6)'}>
                                <Text color={'tema.cor001'} noOfLines={1} textAlign={'right'}>{inverter?jogo.jg01:jogo.jg03}</Text>
                                <Avatar size={'lg'}  mr="-20px"  border={'2px solid #ffffff'}  name={inverter?jogo.jg01:jogo.jg03} src={'/p/'+(inverter?jogo.img01:jogo.img03)} />
                            </Stack>
                        </GradientBorder>
                        {jogo.jg04!==null?
                                <GradientBorder position={'absolute'} right={'10px'} maxW='calc(50% - 10px)'  bottom={'10px'} borderRadius='10px' h='44px' p='2px' bg='rgba(0,0,0,0.4)' align={'center'} justify={'center'} >
                                    <Stack   direction={'row'} h={'40px'} p={'10px'} align={'center'} justify={'center'} borderRadius={'8px'} bg={'rgba('+hexToRGB(cor2B)+' ,0.6)'}>
                                        <Text color={'tema.cor001'} noOfLines={1}>{inverter?jogo.jg02:jogo.jg04}</Text>
                                        <Avatar size={'lg'} mr="-20px" border={'2px solid #ffffff'}  name={inverter?jogo.jg02:jogo.jg04} src={'/p/'+(inverter?jogo.img02:jogo.img04)}/> 
                                    </Stack>
                                </GradientBorder>
                            :null 
                        } 
                    </Flex>
                </>
            )
        break;
        case 3: 
            var old = game.gameStart.acao[game.gameStart.acao.length-1];  
            if(game.gameStart.acao.length>1){
                viewQd.info = <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarPontoGame',form:{}})} zIndex={4} left={'50%'} top={'50%'} position={'absolute'} transform=' translateX(-50%) translateY(-50%)'  colorScheme="green" variant={'solid'}>PONTO</Button>
            }
            if(old.time=='V' && !inverter || old.time=='C' && inverter  ){
                viewQd.cmdL = (
                    <Flex  w={'full'} h={'full'} direction={'column'}>     
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:15,time:time1,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px' transition={'0s'} position={'absolute'} top={'-65px'} ml='-10px'  left={'-30%'}   w={'80%'}  colorScheme={cor1} variant={'outline'}>
                                <Text  noOfLines={'1'} pt={'2px'}>FORA ALTA TOPO</Text>
                            </Button> 
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:16,time:time1,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'} position={'absolute'} top={'-65px'}   left={'50%'}   w={'50%'}  colorScheme={cor1} variant={'outline'}>
                                <Text  noOfLines={'1'} pt={'2px'}>FORA BAIXA TOPO</Text>
                            </Button>
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:17,time:time1,ponto:true}})} fontSize={'10px'}  h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'} transform='rotate(-90deg) translateX(-50%) translateY(-50%)'   position={'absolute'} top={'-22px'}  left={'-40%'}  w={'50%'}  colorScheme={cor1} variant={'outline'}>
                                <Text  noOfLines={'1'} pt={'2px'}>FORA TOPO</Text>
                            </Button> 
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:18,time:time1,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'} transform='rotate(-90deg) translateX(-50%) translateY(-50%)' mb={{base:'-15px',sm:'-22px'}}  position={'absolute'} bottom={'50%'}  left={'-40%'}   w={'50%'}  colorScheme={cor1} variant={'outline'}>
                                <Text  noOfLines={'1'} pt={'2px'}>FORA BASE</Text>
                            </Button> 
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:19,time:time1,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px'  position={'absolute'} bottom={'-65px'} ml='-10px'  left={'-30%'}   w={'80%'}  colorScheme={cor1} variant={'outline'}>
                                <Text  noOfLines={'1'} pt={'2px'}>FORA ALTA BASE</Text>
                            </Button> 
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:20,time:time1,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}} px='0px'  position={'absolute'} bottom={'-65px'}   left={'50%'}  w={'50%'}  colorScheme={cor1} variant={'outline'}>
                                <Text  noOfLines={'1'} pt={'2px'}>FORA BAIXA BASE</Text>
                            </Button> 
                            <Stack direction={'row'} w={'full'} h={'50%'} gap={'5px'} p={'5px'}>
                                <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:9,time:time2}})} h={'100%'} w={'50%'} fontSize={{base:'10px',sm:'14px'}} colorScheme={cor2} variant={'outline'}>
                                    ALTA<br/>TOPO
                                </Button>
                                <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:11,time:time2}})} h={'100%'} w={'50%'} fontSize={{base:'10px',sm:'14px'}} colorScheme={cor2} variant={'outline'}>
                                    BAIXA<br/>TOPO
                                </Button> 
                            </Stack>
                            <Stack direction={'row'} w={'full'} h={'50%'} gap={'5px'} p={'5px'}>
                                <Button  onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:10,time:time2}})} h={'100%'} w={'50%'} fontSize={{base:'10px',sm:'14px'}} colorScheme={cor2} variant={'outline'}>
                                    ALTA<br/>BASE
                                </Button>
                                <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:12,time:time2}})} h={'100%'} w={'50%'} fontSize={{base:'10px',sm:'14px'}}  colorScheme={cor2} variant={'outline'}>
                                    BAIXA<br/>BASE
                                </Button>
                            </Stack>   
                        </Flex>
                )
            }else{
                viewQd.cmdL = (
                    <Flex   w={'full'} h={'full'} direction={'column'}>   
                        <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:13,time:time2,ponto:true}})} fontSize={'10px'} minH={'10px'} px={'0px'} transition={'0s'}  transform='rotate(-90deg) translateX(-50%) translateY(-50%)'   position={'absolute'} top={'-9px'}  right={'-22%'} h={'22px'} w={'48%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'} >REDE TOPO</Text>
                        </Button> 
                        <Button  onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:14,time:time2,ponto:true}})} fontSize={'10px'} minH={'10px'}   px={'0px'} transition={'0s'} transform='rotate(-90deg) translateX(-50%) translateY(-50%)' mb={'-14px'}  position={'absolute'} bottom={'50%'}  right={'-22%'} h={'22px'} w={'48%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>REDE BASE</Text>
                        </Button>
                    </Flex>
                )
            }
            if(old.time=='C' && !inverter || old.time=='V' && inverter ){
                viewQd.cmdR = (
                    <Flex w={'full'} h={'full'} direction={'column'} display={fase==3?'flex':'none'}>   
                        <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:15,time:time2,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px' transition={'0s'}  position={'absolute'} top={'-65px'} mr='-10px'  right={'-30%'}   w={'80%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>FORA ALTA TOPO</Text>
                        </Button> 
                        <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:16,time:time2,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px' transition={'0s'} position={'absolute'} top={'-65px'}   right={'50%'}   w={'50%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>FORA BAIXA TOPO</Text>
                        </Button> 
                        <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:17,time:time2,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px' transition={'0s'} transform='rotate(90deg) translateX(-50%) translateY(-50%)' mt={{base:'-15px',sm:'-22px'}}  position={'absolute'} top={'50%'}  right={'-40%'}   w={'50%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>FORA TOPO</Text>
                        </Button>
                        <Button  onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:18,time:time2,ponto:true}})} fontSize={'10px'}  h={{base:'30px',sm:'40px'}} px='0px' transition={'0s'}  transform='rotate(90deg) translateX(-50%) translateY(-50%)'   position={'absolute'} bottom={'-22px'}  right={'-40%'}  w={'50%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>FORA BASE</Text>
                        </Button>     
                        <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:19,time:time2,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}}  px='0px' position={'absolute'} bottom={'-65px'} mr='-10px'  right={'-30%'}   w={'80%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>FORA ALTA BASE</Text>
                        </Button> 
                        <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:20,time:time2,ponto:true}})} fontSize={'10px'} h={{base:'30px',sm:'40px'}} px='0px'  position={'absolute'} bottom={'-65px'}   right={'50%'}  w={'50%'}  colorScheme={cor2} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>FORA BAIXA BASE</Text>
                        </Button> 
                        <Stack direction={'row'} w={'full'} h={'50%'} gap={'5px'} p={'5px'}>
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:11,time:time1}})} h={'100%'} w={'50%'} fontSize={{base:'10px',sm:'14px'}}   colorScheme={cor1} variant={'outline'}>
                                BAIXA<br/>TOPO
                            </Button>
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:9,time:time1}})} h={'100%'} w={'50%'}  fontSize={{base:'10px',sm:'14px'}}  colorScheme={cor1} variant={'outline'}>
                                ALTA<br/>TOPO
                            </Button> 
                        </Stack>
                        <Stack direction={'row'} w={'full'} h={'50%'} gap={'5px'} p={'5px'}>
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:12,time:time1}})} h={'100%'} w={'50%'}   fontSize={{base:'10px',sm:'14px'}}  colorScheme={cor1} variant={'outline'}>
                                BAIXA<br/>BASE
                            </Button>
                            <Button onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:10,time:time1}})} h={'100%'} w={'50%'} fontSize={{base:'10px',sm:'14px'}}  colorScheme={cor1} variant={'outline'}>
                                ALTA<br/>BASE
                            </Button>
                        </Stack> 
                    </Flex>
                )    
            }else{
                viewQd.cmdR = (
                    <Flex w={'full'} h={'full'} direction={'column'} >    
                        <Button  fontSize={'10px'} minH={'10px'}  onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:13,time:time1,ponto:true}})}  px={'0px'} transition={'0s'} transform='rotate(90deg) translateX(-50%) translateY(-50%)' mt={'-14px'}  position={'absolute'} top={'50%'}  left={'-22%'} h={'22px'} w={'48%'}  colorScheme={cor1} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'}>REDE TOPO</Text>
                        </Button>
                        <Button fontSize={'10px'} minH={'10px'} onClick={()=>sendForm({modulo:'operador',cmd:'marcarAcaoGame',form:{tipo:14,time:time1,ponto:true}})} px={'0px'}  transition={'0s'} transform='rotate(90deg) translateX(-50%) translateY(-50%)'   position={'absolute'} bottom={'-9px'}  left={'-22%'} h={'22px'} w={'48%'}  colorScheme={cor1} variant={'outline'}>
                            <Text  noOfLines={'1'} pt={'2px'} >REDE BASE</Text>
                        </Button> 
                    </Flex>
                ) 
            }     
        break;               
    }  

    return (
        <Stack position={'relative'} transition={'0s'} >
            <Img  zIndex={1} src={"/assets/"+cfg.site+"/img/playaovivo/bg_breachtennis.jpg"} transform={(rotacionar?'rotate(90deg)':'')}   h={(rotacionar?{base: '560px',sm:'560px'}:{base:'255px',sm:'340px'})} />  
            {viewQd.info} 
            <Flex position={'absolute'} zIndex={2} w={{base:'260px',sm:'400px'}} border={'2px solid #ffffff'}  h={rotacionar?{base:'120px',sm:'200px'}:{base:'120px',sm:'200px'}} transform={(rotacionar?'rotate(90deg)':'')}  ml={{base:'-130px',sm:'-200px'}} mt={{base:'-60px',sm:'-100px'}}  bg={'tema.bg001'} top={'50%'} left={'50%'}>
                <Flex position={'relative'} w='calc(50%)' direction={'column'} h={'100%'} bg={'rgba('+hexToRGB(cor1B)+' ,0.3)'}  borderRight={'1px solid #ffffff'} >
                    {viewQd.cmdL}  
                </Flex>
                <Flex position={'relative'}  w='calc(50%)' direction={'column'} h={'100%'} bg={'rgba('+hexToRGB(cor2B)+' ,0.3)'} borderLeft={'1px solid #ffffff'}> 
                    {viewQd.cmdR} 
                </Flex>
            </Flex> 
             <BoxWindow param ={alerta} />
        </Stack>
    );
}
export default QuadraMakerBeachTennis;