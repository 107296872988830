// import React, { useEffect } from 'react';
// import { BrowserRouter, Routes, Route} from "react-router-dom"
import { ChakraProvider, extendTheme} from "@chakra-ui/react" 
import { Router } from "./Router"
import './css/Padrao.css'; 
import 'animate.css'; 
import { cfg } from "./dados/dados";

const theme = extendTheme({ 
  colors: {
    flare: {  
      500: "linear-gradient(to top, #f5af19, #f12711)",
      600: "linear-gradient(to top, #f12711, #f5af19)", 
    },
    gdRed:{
      500:'linear-gradient(to top, #ed213a, #93291e)',
      600:'linear-gradient(to bottom, #ed213a, #93291e)'
    },
    gdYellow:{
      500:'linear-gradient(to top, #ffd200, #f7971e)',
      600:'linear-gradient(to bottom, #ffd200, #f7971e)'
    },
    gdCyan:{
      500:'linear-gradient(to bottom, #36d1dc, #5b86e5)', 
      600:'linear-gradient(to top, #36d1dc, #5b86e5)'
    },
    gdViolet:{
      500:'linear-gradient(to top, #a044ff, #6a3093)', 
      600:'linear-gradient(to bottom, #a044ff, #6a3093)'
    },
    gdGreen:{
      500:'linear-gradient(to top, #11998e, #38ef7d)',
      600:'linear-gradient(to bottom, #11998e, #38ef7d)'
    },
    tema:cfg.theme.cores,
    scroll:{
      bg    : '#000000',
      barra : cfg.theme.cores.cor002,
      borda: '3px',
      largura: '6px',
      hover : 'yellow'
    }
  },
  components: {
    Button: { 
        backgroundSize:"400%",
        backgroundPosition:'top left', 
     
      
    }
  }
});
export const App = () => {
//   useEffect(() => {
//       console.log('reder APP');
//       return ()=>{
//         if(G.conectado){
//           G.desconectarNode();
//           GA.desconectarNode()
//           console.log('reload APP'); 
//         } 
//       }
//   }, []) 
  return (
    <ChakraProvider theme={theme} >
      <Router/>
    </ChakraProvider>
  )
} 
