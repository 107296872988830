import { AbsoluteCenter, Box, Divider, Flex, FormControl, FormLabel, Stack, Switch, Text } from "@chakra-ui/react";
import { CardBox, CardBoxSub, CardBoxTitulo } from "../../../components/CardBox/CardBox";
import { hexToRGB } from "../../../Function";
import { cfg } from "../../../dados/dados";
import BoxWindow from "../../../components/BoxWindow/BoxWindow";
import { useEffect, useState } from "react";
import { GM, sendForm } from "../../../conn/WebMaker";
import InputCampo from "../../../components/Input/modelos/InputCampo";
import { FaCheck, FaIndent, FaKey, FaMailBulk, FaSignInAlt, FaUser, FaUserPlus, FaUsers } from "react-icons/fa";
import { Bt1 } from "../../../components/Button/ButtonView";
import SelectCampo from "../../../components/Input/modelos/SelectCampo";
import { HiUserGroup } from "react-icons/hi";

const CadastroJogador =function(){
    const [alerta,setAlerta] = useState({});  
    const [erroM,setErroM] = useState({})
    console.log(GM)

    let dados = null; 
    
    const [reload,setReload]= useState(0);
    useEffect(()=>{   
        var interV = setInterval(()=>{
            try {
                if(reload!=GM.makerGrupos.up){
                    setReload(GM.makerGrupos.up); 
                }  
            } catch (error) {
                
            } 
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);

    useEffect(()=>{

    },[alerta]) 
    var viewOP = [];
    if(typeof GM.makerGrupos!='undefined'){
        Object.values(GM.makerGrupos.dados).forEach((g)=>{
            viewOP.push({nome:g.nome,valor:g.id})
        })
    }   
    return (
        <CardBox px='25px'>
            <CardBoxTitulo textAlign='left'>Cadastro Usuário</CardBoxTitulo>
            <CardBoxSub>Preencha todos os dados abaixo:</CardBoxSub>
            <Box pos={'relative'}   as={'form'} py={'15px'}  onSubmit={(e)=>{
                e.preventDefault();   
                setAlerta({load:true}) 
                let json = {};  
                new FormData(e.target).forEach(function(value, prop){ 
                    json[prop] = value;
                }); 
                sendForm({ modulo:'usuario',cmd:'cadastro',form:json,callback:(result)=>{   
                    if(result.erro){
                        result.fechar =true;
                        result.size = 'md';  
                        setAlerta(result);
                    }else{  
                        setAlerta({url:'maker/usuario/lista/'});  
                    } 
                }}); 
            }} >
                <Stack w={'100%'} direction={'row'} gap={'20px'}>
                    <InputCampo
                        icone={FaIndent} 
                        titulo='Nome'
                        name='nome'
                        placeholder="Digite o nome completo"
                        error={erroM.login}
                        w='60%'
                    />
                    <SelectCampo
                        icone={FaUsers} 
                        titulo='Grupo Usuário'
                        name='grupo'
                        placeholder="Selecione o Grupo"
                        error={erroM.login}
                        value={viewOP}
                        w='40%'
                    />
                </Stack>
                <Stack w={'100%'} direction={'row'} gap={'20px'}>
                    <InputCampo
                        icone={FaUser}
                        tipo='user'
                        w='50%'
                        titulo='Usuário'
                        name='login'
                        placeholder="Digite o login"
                        error={erroM.login}
                    />
                    <InputCampo
                        icone={FaMailBulk}
                        tipo='user'
                        w='50%'
                        titulo='E-mail'
                        name='email'
                        placeholder="Digite o e-mail"
                        error={erroM.email}
                    />
                </Stack>
                <Stack w={'100%'} direction={'row'} gap={'20px'}>
                    <InputCampo
                        icone={FaKey}
                        tipo='password'
                        w='50%'
                        titulo='Senha'
                        name='senha1'
                        placeholder="Digite o senha"
                        error={erroM.senha1}
                    />
                    <InputCampo
                        icone={FaCheck}
                        tipo='password'
                        w='50%'
                        titulo='Confirmar Senha'
                        name='senha2'
                        placeholder="Confirme a senha"
                        error={erroM.senha2}
                    />
                </Stack>
                <Box position='relative' py={'20px'}  >
                    <Divider />
                    <AbsoluteCenter bg='tema.cor001' p='4px 20px' borderRadius={'lg'}>
                        Acesso Módulos
                    </AbsoluteCenter>
                </Box>
                <Stack w={'100%'} direction={'row'} gap={'20px'}  pt={'15px'} align={'center'} justify={'center'}>
                    <Flex>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='maker-alerts' mb='0' color={'tema.cor001'}>
                                Maker?
                            </FormLabel>
                            <Switch id='maker-alerts' name="sOpe"  value={'S'}/>
                        </FormControl>
                    </Flex>
                    <Flex>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='marcador-alerts' mb='0'  color={'tema.cor001'}>
                                Marcador?
                            </FormLabel>
                            <Switch id='marcador-alerts' name="sAdm"  value={'S'}/>
                        </FormControl>
                    </Flex>
                    <Flex>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='odds-alerts' mb='0'  color={'tema.cor001'}>
                                Gestão ODDs?
                            </FormLabel>
                            <Switch id='odds-alerts' name="sOdd" value={'S'}/>
                        </FormControl>
                    </Flex>
                    <Flex>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='sites-alerts' mb='0'  color={'tema.cor001'}>
                                Gestão Sites?
                            </FormLabel>
                            <Switch id='sites-alerts' name="sSit"  value={'S'} />
                        </FormControl>
                    </Flex> 
                </Stack>
                <Bt1 bg1={ '#000000'} bg2={ '#222222'}   leftIcon={<FaUserPlus />}   mt={'20px'} >Cadastrar Usuário</Bt1>
            </Box>
            <BoxWindow param={alerta} />
        </CardBox>
    )
}
export default CadastroJogador;