import { Button, Icon, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react"; 
import { setSession, user } from "../../../conn/WebPainel";
import BoxPainelUser from "./BoxPainelUser";
import { FaDoorOpen, FaPowerOff, FaRegUserCircle, FaUserCircle } from "react-icons/fa";
import BoxWindow from "../../../components/BoxWindow/BoxWindow";

const UserBox = function(){ 
    const [alerta,setAlerta] = useState({});  
    const [open,setOpen] = useState(false);
    const [cmd,setCmd] = useState('');
    const [reload,setReload] = useState(null);
    
    const [usuario,setUsuario] = useState('');
    useEffect(()=>{
        var tempUsu = JSON.stringify(user);
        var interV =setInterval(()=>{ 
            if(tempUsu!=JSON.stringify(user)){
                tempUsu=JSON.stringify(user);
                console.log(user);
                setReload({});
            } 
        },1000);
        return ()=>{
            clearInterval(interV)
        }
    },[reload])
    let view = null
    if(user == null){ }else if(typeof user.usuario =='undefined'){
        view= <Stack direction={'row'} pr={{base:'10px',md:'20px'}} >
            <Button  onClick={()=>{
                setCmd('register');
                setOpen(true);
            }} variant={'outline'} size={{base:'xs',md:'lg'}} borderColor={'tema.cor001'}  color={'tema.cor001'} _hover={ {borderColor:'tema.cor002',  color:'tema.cor002'}}>Registre-se</Button>
            <Button  onClick={()=>{
                setCmd('login');
                setOpen(true);
            }} bg={'tema.cor002'}  size={{base:'xs',md:'lg'}} color={'tema.cor001'} _hover={ {bg:'tema.cor001',  color:'tema.cor002'}}>Login</Button>
            
        </Stack>
    }else{
        view= <Stack direction={'row'} pr={{base:'10px',md:'20px'}} >
             <Button  onClick={()=>{
                setCmd('deposit');
                setOpen(true);
            }} bg={'tema.cor002'}  size={{base:'xs',md:'lg'}} color={'tema.cor001'} _hover={ {bg:'tema.cor001',  color:'tema.cor002'}}>Depositar</Button>
            <Button leftIcon={<FaRegUserCircle/>} onClick={()=>{
                setCmd('register');
                setOpen(true);
            }} variant={'outline'} size={{base:'xs',md:'lg'}} borderColor={'tema.cor001'}  color={'tema.cor001'} _hover={ {borderColor:'tema.cor002',  color:'tema.cor002'}}>R$ 0,00</Button>
            <Button  onClick={()=>{
                setAlerta({load:true});
                setTimeout(()=>{
                    
                    setAlerta({
                        fechar:true,
                        titulo:'Atenção',
                        size:'md',
                        mensagem:'Deseja realmente sair de sua conta?', 
                        botao:[
                            {
                                nome:'SIM',
                                color:'green',
                                cmd:()=>{
                                    setSession({token:'',dados:{}});
                                    setAlerta({close:true})
                                }
                            },
                            {
                                nome:'NÃO',
                                color:'red'
                            }
                        ]
                    });
                })
            }} variant={'outline'} size={{base:'xs',md:'lg'}} colorScheme="red"   px='0'><Icon as={FaDoorOpen}/></Button>
        </Stack>
    }
    return <>
        {view}
        <BoxPainelUser open={open} cmd={cmd} setBox={(props)=>{
            setCmd(props.cmd);
            setOpen(props.open);
        }} onClose={()=>{
            setCmd('')
            setOpen(false)
        }}/>
        <BoxWindow param ={alerta} />
    </>
}
export default UserBox;