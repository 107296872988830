import { Box, Flex, Img } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {Helmet} from 'react-helmet'   
import { cfg } from "../../dados/dados"; 
import {user,conectarServer,desconectarServer} from "../../conn/WebGame";
// pages

import Pages from "../../pages/Game";
import { Navigate } from "react-router-dom"; 
import MenuGame from "./Game/MenuGame";

export const PageGame = [ 
    {  nome  :   "Login",       slug   :   "login"          ,page: 'loginGame',        login: false, url: '/game/dashboard'},
    {  nome  :   "Marcação",    slug   :   "dashboard"      ,page: 'DashboardGame',    login: true,  url: false},
];
const LayoutGame=(props)=>{ 
    const base =useRef();
    const [web,setWeb] = useState(null); 
    const [reload,setReload] = useState(null); 
    var pag = null;
    PageGame.forEach((p)=>{ 
        if(props.page[1] == p.slug.toUpperCase()){
            pag =p;
        }else if(props.page[1]+'/'+props.page[2] == p.slug.toUpperCase()){
             pag =p; 
        }
    })  
    if(web == null){
        conectarServer()
    }  
    useEffect(()=>{
        
    },[user]);  
    useEffect(()=>{
        var interV = setInterval(()=>{ 
            if(user!==null && reload!==user.dataUP){  
                setReload(user.dataUP);
            } 
        },1000) 
        return ()=>{ 
            clearInterval(interV);
            desconectarServer();
        }
    },[])   
    if(pag == null){
        return <Navigate to='/game/login' />;
    }else if(user==null){
        return 'load';
    }else if(typeof user.usuario =='undefined'){   
        if(pag.login){ 
            pag = PageGame[0];
        }  
        
        const ViewPage = Pages[pag.page];
        return (
            <Box position='relative'>
                <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
                    <Img opacity={'0.8'}   filter='blur(25px)' objectFit='fill' src={"/assets/"+cfg.site+"/img/game/bg.jpg"}  h={{base:'100%' }} minW={'auto'} w={'100%'}/>  
                </Box>
                <Flex zIndex={'2'} position={'relative'} ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} minH={'100vh'} overflowX={'hidden'} overflowY={'auto'}>
                    <ViewPage/>
                </Flex>
            </Box>
        )  
    }else{
        if(!pag.login){
            pag = PageGame[0];
            return <Navigate to={pag.url} />;
        }else{  
            const ViewPage = Pages[pag.page];
            return (
                <Flex position={'relative'}  w='100vw' h='100vh' overflowX={'hidden'} overflowY={'auto'}>
                    <Flex position='relative' bg={'tema.bg001'} maxW={'1280px'} m={'0 auto'}> 
                        <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
                            <Img opacity={'0.8'}   filter='blur(25px)' objectFit='fill' src={"/assets/"+cfg.site+"/img/game/bg.jpg"}  h={{base:'100%' }} minW={'auto'} w={'100%'}/>  
                        </Box>
                        <Flex zIndex={'2'}    position={'relative'}  ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} w={'100vw'} h={'auto'}  >
                            <MenuGame pag={pag} page ={props.page}/>
                            <Flex position={'relative'} pl={{base:'0',md:'285px'}} pr={'15px'} top={{base: '60px' , md:'90px'}}  direction={'column'}   w='100%' h={'auto'}   >
                                <ViewPage/> 
                            </Flex> 
                        </Flex>
                    </Flex>
                </Flex>
                 
            )
        }
        
    }
    
    
}
export default  LayoutGame;