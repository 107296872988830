import { Box, Img, Stack } from "@chakra-ui/react"
import { cfg } from "../../../dados/dados";

const QuadraBeachTennis = function(){

    return <Stack>
        <Img src={"/assets/"+cfg.site+"/img/playaovivo/bg_breachtennis.png"} />  
        <Box position={'absolute'} w={'280px'} bg={'tema.bg0001'} bottom={'10px'} left={'10px'}>

        </Box>
    </Stack>
}
export default QuadraBeachTennis;